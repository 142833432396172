import React,{useState} from "react";
import { RiEmotionHappyFill } from "react-icons/ri";
import styled from "styled-components";


const Chats = ({item,show}) => {

  const msgList = [
    {senderId:1,name:"Adeyemi Bolaji",text:`Please, drop your questions here and someone will attend to them.
    Thank you`,time:"11:03am"},
    {senderId:2,name:"Hannatu Yusuf",text:`I am not clear on N0 2, can you please explain.`,time:"11:10am"},
    {senderId:3,name:"Adeyemi Bolaji",text:`@Hannatu your question will be addressed shortly.`,time:"11:15am"},
    {senderId:4,name:"Chuks Martins",text:`How do I begin to work towards the deadline.`,time:"11:45am"},
    
  ];
  
  const [message, setMessage] = useState("");

  const sendMsg = (msg) =>{
    if(msg ===""){
      return;
    }
    let chat ={
      senderId:msgList.length +1,
      name:"pope francis",
      text:message,
      time:new Date().toLocaleTimeString()
    }
    msgList.push(chat);
    console.log(msgList);
    setMessage("");
  }

  

  return(
    <Wrap>
      <div className="dismiss-div">
        <h6>Chat Room</h6>
        <span onClick={() =>{item("");show(false)}}>x</span>
      </div>
      <div className="msg-wrap">
        <div className="chat-msg">
          <ul className="message-list">                 
              {msgList.map(message => {
                return (
                <li key={message.senderId}>
                  <div>
                    {message.name}
                  </div>
                  <div className="chat">
                    {message.text}
                  </div>
                  <span>{message.time}</span>
                </li>
              )
            })}
          </ul>
        </div>

        <div className="send-div">
          <div className="m-border">
            <div className="quick-action">
              <p className="quick-reaction">Inspiring</p>
              <p className="quick-reaction">Halleluyah</p>
              <p className="quick-reaction">Wonderful</p> 
            </div>
            <form className="chat-div" onSubmit={(e) =>e.preventDefault()}>
              <RiEmotionHappyFill className="icon"/>
              <input type="text" value={message} onChange={(e) =>setMessage(e.target.value)} 
                placeholder="Type here" disabled={message.length ===100?true:false} 
                className="msg"
              />
              <button type="submit" className="sendBtn" onClick={()=>{sendMsg(message)}}
              >send</button>
            </form>
          </div>
          <div className="msg-limit">
            <span>{message.length}/100</span>
          </div>
        </div>
      </div>
    </Wrap>
  )
}
export default Chats;

const Wrap = styled.div `
  *{
    margin:0;
    padding:0;
    box-sizing:border-box;
  }
  border: 1px solid #ccc;
  height:100%;
  overflow-y:auto;
  padding: 0;
  position:relative;

  .dismiss-div{
    display:flex;
    justify-content:space-between;
    font-size:18px;
    align-items: center;
    margin: 5px 10px 15px 10px;

    span{
      color: red !important;
      font-weight:700;
      cursor:pointer;
      font-size:18px;
    }
  }

  .chat-msg{
    height:500px;
    overflow-y:auto;
    .message-list{
      li{
        padding:17px;
        div{
          margin:0;
        }
        .chat{
          background: #00B4D854;
          padding:8px;
          font-size:14px;
          line-height:16px;
          box-shadow: 0px 1px 1px 0px #CAF0F83D;
          border-bottom-left-radius:15px;
          border-bottom-right-radius:15px;
          border-top-right-radius:15px;
        }
        span{
          float:right;
        }
      }
    }
  }
  .send-div{
    width:100%;
    
    position:relative;
    bottom:0;
    right:0;

    .m-border{
      border:1px solid #ccc;
    }
    .quick-action{
      display:flex;
      align-items:center;
      gap:5px;
      justify-content:space-between;
      margin:17px 10px;

      .quick-reaction{
        background: #CAF0F8;
        padding:8px;
        font-size:13px;
        cursor:pointer;
      }
    }

    .chat-div{
      .icon{
        color:#553b03;
      }
      .disable{
        pointer-event:none;
      }
      .msg{
        border:none;
        margin:0 5px;
      }
      .msg:focus{
        outline:none;
      }
      .sendBtn{
        border:none;
        color:var(--btnColor);
        background:#fff;
        padding:5px;
        font-weight:500;
        font-size:16px;
      }

      @media screen and (max-width:400px){
        input{
          width:100%;
        }
        .sendBtn{
          position:relative;
          width:100%;
          margin:5px auto;
        }
      }
      @media screen and (max-width:450px){
        input{
          width:70%;
        }
      }
    }
    .msg-limit{
      span{
        float:right;
      }
    }
  }


`;