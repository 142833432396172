import angry from "./angry.svg";
import confused from "./confused.svg";
import happy from "./happy.svg";
import sad from "./sad.svg";
import love from "./love.svg";


const emoji ={
  angry,
  confused,
  happy,
  sad,
  love,
}
export default emoji;