import React,{useState, useEffect} from "react";
import styled from "styled-components";
import Verify from "../components/Verification";


const Verification = () => {
  
  const [user, setUser] = useState("");

   useEffect(() =>{
     setUser(sessionStorage.getItem("user"));
     console.log(user);
    },[user])

  return(
    <Wrapper>
      {user ===""?<p>Loading....</p>:
        <>
          <div>
            <Verify  user={user}/>
          </div>
        </>
      }
    </Wrapper>
  )
}
export default Verification;

const Wrapper = styled.div `

`;