import React from "react";
import styled from "styled-components";
import dropdown from "./Layout/DashboardLayout/dropDown.svg";


const Overlay = (props) => {
   const {title,dismiss} = props;

  return(
    <Wrapper>
      <div className="wrap">
        <div className="dismiss-div">
          <p>{title}</p>
          <span onClick={()=>dismiss("")}>x</span>
        </div>
        <hr/>
        <div className="schedule-time">
          <div>
            <h5>Schedule publish</h5>
            <p>Choose a date you want to publish</p>
          </div>
          <input id="overlay-date" type="date" />
        </div>
        <p className="now">Publish Now</p>
      </div>
    </Wrapper>
  )
}
export default Overlay;

const Wrapper = styled.div `
  *{
    margin:0;
    padding:0;
    box-sizing:border-box;
  }
  .wrap{
    max-width:400px;
    border:0.5px solid #3333;
    padding:10px;
    border-radius:5px;

    .dismiss-div{
      display:flex;
      justify-content:space-between;
      position:relative;
      span{
        color:red;
        font-size:24px;
        position:absolute;
        bottom:3px;
        right:10px;
        font-weight:500;
        cursor:pointer;
      }
    }
    .schedule-time{
      display:flex;
      align-items:center;
      justify-content:space-between;
      margin:10px 0;

      h5{
        margin:0;
      }
      #overlay-date{
        border:none;
        width:50px;
        background:#eee;
      }
      #overlay-date:focus{
        outline:none;
      }
      #overlay-date::-webkit-calendar-picker-indicator{
        color: transparent;
        background: url(${dropdown});
        background-repeat:no-repeat;
        background-position:center;
        cursor:pointer;
        transform:rotate(-90deg);
        z-index: 1;
      }
    }
    .now{
      cursor:pointer;
      font-size:22px;
      font-weight:500;
      width:140px;
      padding:5px;
      transition: all 1s ease;
    }
    .now:hover{
      background:#0077b6;
      color:#fff;
      border-radius:5px;
    }
  }
`;
