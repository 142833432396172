import event1 from "./event1.png";
import event2 from "./event2.png";
import event3 from "./event3.png";
import eventicon from "./event-icon.png";


const LiveEvent = [
  {
    id:1,
    eventFlayer: event1,
    icon:eventicon,
    title:"The Beliver's Tabanacle",
    desc: "Lorem Ipsum is a simple dummy text",
    live: false
  },
  {
    id:2,
    eventFlayer: event2,
    icon:eventicon,
    title:"The Beliver's Tabanacle",
    desc: "Lorem Ipsum is a simple dummy text",
    live: true
  },
  {
    id:3,
    eventFlayer: event3,
    icon:eventicon,
    title:"The Beliver's Tabanacle",
    desc: "Lorem Ipsum is a simple dummy text",
    live: false
  },
]
export default LiveEvent;