import React from "react";
import styled from "styled-components";
import {FaEye} from "react-icons/fa";
import eventData from "./LiveEvent/LiveEventData";



const LiveEvent = ({item,show}) => {

  return(
    <Wrapper>
      <div className="dismiss-div">
        <h6>Live Events</h6>
        <span onClick={() =>{item("");show(false)}}>x</span>
      </div>
      <div className="events-div">
        <div>
          {eventData.map(livevent =>{
            return(
              <div className="event-card" key={livevent.id}>
                <div className="labels">
                  <label className="live">Live</label>
                  <label className="viewers"><FaEye />3k</label>
                </div>
                <img src={livevent.eventFlayer} alt="event" />
                <div className="event-details">
                  <img src={livevent.icon} alt="event" />
                  <div>
                    <h6>{livevent.title}</h6>
                    <p>{livevent.desc}</p>
                  </div>
                </div>
                {livevent.live ===true? <button className="join">Join</button>:null}
              </div>
            )
          })}
          
        </div>
      </div>
    </Wrapper>
  )
}
export default LiveEvent;

const Wrapper = styled.div `
  *{
    margin:0;
    padding:0;
    box-sizing:border-box;
  }
  border: 1px solid #ccc;
  height:100%;
  overflow-y:auto;
  padding: 0;
  position:relative;

  .dismiss-div{
    display:flex;
    justify-content:space-between;
    font-size:18px;
    align-items: center;
    margin: 5px 10px 15px 10px;

    span{
      color: red !important;
      font-weight:700;
      cursor:pointer;
      font-size:18px;
    }
  }

  .events-div{
    padding:10px;
    height:640px;
    overflow-y:scroll;

    .event-card{
      position:relative;
      margin:21px auto;
      box-shadow: 0px 3px 4px 0px #00000014;
      border: 0.5px solid #00000026;
    }
    
    .labels{
      position:absolute;
      display:flex;
      justify-content:space-between;
      width:95%;
      margin:0 5px;
      left: 3px;
      top:10px;

      .live{
        background:#fff;
        color:red;
        padding:2px 15px;
        font-weight:400;
      }
      .viewers{
        background:#000000c4;
        color:#fff;
        padding:2px 15px;
        font-weight:500;
      }
    }
    .event-details{
      margin:5px;
      display:flex;
      gap:10px;
      align-items:center;

      p{
        font-size:12px;
      }
    }
    .join{
      border:none;
      background:var(--btnColor);
      padding:5px 25px;
      font-weight:700;
      color:#fff;
      margin:0 6px 6px 6px;
    }
  }
`;