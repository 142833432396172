import React from "react";
import styled from "styled-components";
import profileImg from "./participantImg";

const  participants = ({item,show}) => {
  
  return(
    <Wrapper>
      <div className="dismiss-div">
        <h6>participants online</h6>
        <span onClick={() =>{item("");show(false)}}>x</span>
      </div>
      <div className="pro-div">
        <div className="viewer">
          <img src={profileImg.profile1} alt="viewer"/>
          <p>Chioma Chukwu</p>
        </div>
        <div className="viewer">
          <img src={profileImg.profile2} alt="viewer"/>
          <p>Pelumi Adams</p>
        </div>
        <div className="viewer">
          <img src={profileImg.profile3} alt="viewer"/>
          <p>Precious Richards</p>
        </div>
        <div className="viewer">
          <img src={profileImg.profile4} alt="viewer"/>
          <p>Yemi Yusuf</p>
        </div>
        <div className="viewer">
          <img src={profileImg.profile5} alt="viewer"/>
          <p>Felicia Michael</p>
        </div>
        <div className="viewer">
          <img src={profileImg.profile6} alt="viewer"/>
          <p>Femi James</p>
        </div>
        <div className="viewer">
          <img src={profileImg.profile7} alt="viewer"/>
          <p>Amaka Chwukudi</p>
        </div>
        <div className="viewer">
          <img src={profileImg.profile8} alt="viewer"/>
          <p>Daine Felix</p>
        </div>
        <div className="viewer">
          <img src={profileImg.profile9} alt="viewer"/>
          <p>Gbenga Adeyemi</p>
        </div>
        <div className="viewer">
          <img src={profileImg.profile10} alt="viewer"/>
          <p>Chuks Stephen</p>
        </div>
        <div className="viewer">
          <img src={profileImg.profile11} alt="viewer"/>
          <p>Paul Felix</p>
        </div>
        <div className="viewer">
          <img src={profileImg.profile12} alt="viewer"/>
          <p>Sandra Idoko</p>
        </div>
        <div className="viewer">
          <img src={profileImg.profile13} alt="viewer"/>
          <p>Mary Paul</p>
        </div>
      </div>
    </Wrapper>
  )
}
export default participants;

const Wrapper = styled.div `

  *{
    margin:0;
    padding:0;
    box-sizing:border-box;
  }
  border: 2px solid #ccc;
  padding: 10px;

  .dismiss-div{
    display:flex;
    justify-content:space-between;
    font-size:18px;
    align-items: center;
    margin: 5px auto 15px auto;

    span{
      color: red !important;
      font-weight:700;
      cursor:pointer;
      font-size:18px;
    }
    @media screen and (max-width:768px){
      font-size:10px !important;
    }
  }
  .pro-div{
    height:620px;
    overflow: auto;

    .viewer{
      display:flex;
      gap:10px;
      margin:5px 0;
      align-items:center;

      @media screen and (max-width:768px){
        p{
          font-size:12px;
        }
      }
    }
  }
`;