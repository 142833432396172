import sticker from "./sticker.svg";
import logo from "./logo.png";
import playstore from "./playstore.png";
import phone from "./phones.png";
import applestore from "./appstore.png";
import feed from "./feed.png";
import analytic from "./analytics.png";
import upload from "./upload.png";
import content from "./content.png";
import bgWrk from "./groupwrk.png";
import iconEvent from "./icon-event.svg";
import iconSign from "./icon-sign.svg";
import iconCreate from "./icon-create.svg";
import iconNote from "./icon-note.svg";
import iconSwitch from "./icon-switch.svg";
import iconBoard from "./icon-board.svg";
import iconDownload from "./icon-download.svg";
import iconStream from "./icon-stream.svg";
import iconPublish from "./icon-publish.svg";
import iconChat from "./icon-chat.svg";
import iconExplore from "./icon-explor.svg";
import iconShare from "./icon-share.svg";
import iconUp from "./IconUp.svg";
import explainer from "./explainer.svg";
import wifi from "./wifi.png";
import flow from "./app-flow.png";


const homeImages = {
    sticker,
    logo,
    playstore,
    applestore,
    phone,
    feed,
    analytic,
    upload,
    content,
    bgWrk,
    iconEvent,
    iconShare,
    iconSign,
    iconSwitch,
    iconPublish,
    iconCreate,
    iconChat,
    iconDownload,
    iconBoard,
    iconStream,
    iconNote,
    iconUp,
    iconExplore,
    explainer,
    wifi,
    flow,
}

export default homeImages;