import React,{useEffect, useState} from "react";
import styled from "styled-components";
import Feed from "./Feed";
import Navs from "./Nav";
import asidImg from "./asideImg.png";
import Profile from "./Profile";
import Analytics from "./Analytics";
import Dashboard from "./Dashboard";
import Board from "./Board";
import CreateEvent from "./CreateEvent";
import {  Route, Switch, } from "react-router-dom";
import profileImg from "../../../util/img/dorathy.png";
import Axios from "axios";



const DashboardLayout = (props) => {

  const [categories, setCategories] = useState([]);
  const config = {
    Headers:{
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
  };
  const fetchCategories = async() =>{
    const {data} = await Axios.get('https://elroi.live/api/v1/categories',config);
    setCategories(data.data.categories);
    console.log(data)
  }
  const username = sessionStorage.getItem("username");
  const text ="Lorem Ipsum is simply dummy text of the printing and typesetting industry.";
  
  useEffect(() =>{
    fetchCategories();
  },[]);
  return(
    <Wrap>
      <Navs username={username} profileImg={profileImg}>
          <Switch>
            <Route exact path="/user/dashboard">
              <Feed  text = {text} asidImg={asidImg} /> 
            </Route>
            <Route exact path="/user/dashboard/profile">
              <Profile profileImg={profileImg} name ={username} id ="XVFG126578899" 
                favourite="10" events ="20" following ="10"
              /> 
            </Route>
            <Route exact path="/user/dashboard/overview">
              <Dashboard text = {text} asidImg={asidImg} />
            </Route>
            <Route  path="/user/dashboard/analytics">
              <Analytics text = {text} asidImg={asidImg} />
            </Route>
            <Route exact path="/user/dashboard/board">
              <Board />
            </Route>
            <Route exact path="/user/dashboard/event">
              <CreateEvent categories={categories}/>
            </Route>
            
          </Switch>
      </Navs>
    </Wrap>
  )
}
export default DashboardLayout;

const Wrap = styled.main `
  
  *{
    padding:0;
    margin:0;
    box-sizing:border-box;
  }
  .development{
    text-align:center;
    margin:10% auto;

    button{
      background:#00776b;
      color:#fff;
      width:120px;
      height:45px;
    }
  }
`;