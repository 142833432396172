import React from "react";
import styled from 'styled-components';
import Preference from "../components/Preference";



const  PreferenceSetting = () =>{

  return(
    <Wrap>
      <Preference />
    </Wrap>
  )
}
export default PreferenceSetting;

const Wrap = styled.div `

`;