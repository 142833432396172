import React,{useState} from "react";
import styled from "styled-components";
import Images from "../util/img/regImages/index";
import Loader from "react-loader-spinner";


const Verify = ({verified,user}) => {

  const [loading, setLoading] = useState(false);

  const verifyUser = () =>{

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      window.location.href="/welcome";
    }, 4000);
  }

  return(
    <VerifyWrap>
      <div className="text-center">
        <img className="img-fluid" src={Images.verify} alt="verifyimage" />
        <h1>Congrats {user} on signing up to ElRoi!!</h1>
        <p>A link has been sent to your mail for verification.</p>
        {loading ===true?<Loader type="ThreeDots" />:
          <button className="btn verify-btn" onClick={() =>verifyUser()}>Verify Your Self</button>
        }
      </div>
    </VerifyWrap>
  )
}
export default Verify;

const VerifyWrap = styled.div `
  
  div{
    margin: 20px auto;
    padding:15% auto 12% auto;
    width:80%;
  }
  img{
    width:30%;
  }
  h1{
    font-size:24px;
    font-weight:700;
    line-height:35px;
    margin:12px  auto 5px auto;
    width:558px;
  }
  p{
    font-size:24px;
    margin: 0 auto 22px auto;
    font-weight: 400;
    width:418px;
    line-height:30px;
  }
  .verify-btn{
    height:65px;
    padding:15px 100px 15px 100px;
    font-size:26px;
    font-weight:700;
    color:#fff;
    border-radius:11px;
    background: var(--btnColor);
  }
  @media screen and (max-width:768px){
    div{
      margin: 20px auto;
      padding:5% auto 2% auto;
    }
    img{
      width:60%;
    }
    h1{
      font-size:18px;
      line-height:25px;
      margin:12px  auto 5px auto;
      width:90%;
    }
    p{
      font-size:16px;
      margin: 0 auto 22px auto;
      width:300px;
      line-height:20px;
    }
    .verify-btn{
      height:45px;
      padding:10px 50px 10px 50px;
      font-size:16px;
      font-weight:700;
    }
  }
`;