import React from "react";
import styled from "styled-components";
import Images from "../util/img/regImages";


const Welcome = ({user}) => {


  return (
    <Wrapper>
      <div className="text-center">
        <h1 className="welcome-text">{user}!</h1>
        <img src={Images.welcome} alt="welcome pix" />
        <h1>Here’s your Personal event ID</h1>
        <p className="e-id">RiseEmpire5678</p>
        <button className="btn btn-start" onClick={() => window.location.href="/preferences"}>Get Started</button>
      </div>
    </Wrapper>
  )
}
export default Welcome;

const Wrapper = styled.div `

  .welcome-text{
    margin:3% 0 2% 15%;
    width:max-content;
    text-align:left !important;
  }
  div{
    margin: 6px auto;
    position: relative;
  }
  img{
    width:18%;
  }
  h1{
    font-size:30px;
    line-height:35px;
    margin:32px auto 22px auto;
  }
  .e-id{
    font-size:28px;
    line-height:32px;
    color:var(--primaryColor);
    margin:0 auto 34px auto;
  }
  .btn-start{
    color:#fff;
    background:var(--btnColor);
    font-size:26px;
    line-height:30px;
    font-weight:600;
    padding: 10px 65px;
  }

  @media screen and (max-width:768px){
    div{
      margin:6% auto;
    }
    img{
      width:50%
    }
    h1{
      font-size:24px;
      line-height:25px;
      margin:32px auto 22px auto;
    }
    .e-id{
      font-size:18px;
      font-weight:600;
      line-height:22px;
      color:var(--primaryColor);
      margin:0 auto 24px auto;
    }
  }
`;