import React, { useState } from "react";
import styled from "styled-components";
import ClientStreamLayout from "../components/Layout/ClientStreamLayout";
import ReactPlayer from 'react-player';
import Participants from "../components/Layout/ClientStreamLayout/participants";
import Chats from "../components/Layout/ClientStreamLayout/Chat";
import LiveEvent from "../components/Layout/ClientStreamLayout/LiveEvent";



const ClientStream = () =>{

  const [aside, setAside] = useState(true);
  const handleAside = (option) =>{
    setAside(option);
    console.log(aside)
  }
  const [asideItem, setAsideItem]= useState("live");
  const handleItem = (item) => {
    if(asideItem === item){
      setAsideItem("")
      return;
    }
    setAsideItem(item);
  }
  return(
    <Wrap>
      <div className={aside ===true && asideItem !==""?"show-aside":""}>
        <div className={aside ===true && asideItem !==""?"layout":""}>
          <ClientStreamLayout item={handleItem} show={handleAside} title="Abirs Alliance Quarterly Meeting">
              <ReactPlayer 
                url='https://www.youtube.com/watch?v=96OrBX1epXM'
                
                width="100%"
                height="450px"
                controls={true}
                volume={1}
              />
              {/* <video controls width="100%">

                <source src="https://www.youtube.com/watch?v=96OrBX1epXM"
                        type="video/mp4"/>

                Sorry, your browser doesn't support embedded videos.
              </video> */}
          </ClientStreamLayout>
        </div>
        <div className={aside ===true && asideItem !==''?"aside":"hide"}>
          <div className={asideItem ==="participant"? "participant": "hide"}>
            <Participants item={handleItem} show={handleAside}/>
          </div>
          <div className={asideItem ==="chat"? "chat": "hide"}>
            <Chats item={handleItem} show={handleAside}/>
          </div>
          <div className={asideItem ==="live"? "active-event": "hide"}>
            <LiveEvent item={handleItem} show={handleAside}/>
          </div>
        </div>
      </div>
    </Wrap>
  )
}
export default ClientStream;

const Wrap = styled.div `
  .show-aside{
    display:grid;
    grid-template-columns: 4fr 1fr;
    gap:10px;
    margin:2% 1%;

    @media screen and (max-width:600px){
      grid-template-columns:100% !important;
      gap:0px;
      position:relative;
    }
  }
  
  .hide{
    display:none;
  }
  .layout{
    border:4px solid #ccc;
    border-radius:5px;
    
    @media screen and (max-width:600px){
      width:100% !important;
    }
  }
  .aside{

    @media screen and (max-width:600px){
      position:absolute;
      right:5px;
      top:0;
      background:#c2c3c4f1;
      height:100%;
      width:60%;
    }
  }
  .participant, .chat{
    height:100% !important;
  }
`;