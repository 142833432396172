import React from "react";
import styled from "styled-components";
import EventsCard from "../../EventsCard";
import {attended,registered,hosts, upComingEvents,liveEvent} from "../../Data/data.js";
import HostFollowed from "../../HostFollowed";
import AsideSection from "./AsideSection";
import { FaCaretDown, FaSearch } from "react-icons/fa";
// import ClientStreamLayout from "../ClientStreamLayout";



const Feed = (props) =>{

  const {asidImg, text} = props;

  return(
    <Wrapper>
      <div className="canvas">
        <div className="aside-content">
          <AsideSection image={asidImg} text={text} />
        </div>
        <div className="main-content">
          <div className="search-div">
            <div>
              <FaSearch />
              <input type="text" placeholder="search" />
            </div>
            <p>Filter</p>
            <div>
              <input type="text" placeholder="last 30 days" />
              <FaCaretDown />
            </div>
          </div>
          <div className="activity-div">
            <div >
              <EventsCard title="Now Live" details={liveEvent}/>
              <EventsCard  title ="Top Upcoming Events" details={upComingEvents}/>
              <EventsCard title ="Registered Events" details={registered} />
              <EventsCard title="Previous Attended Events" details={attended} />
            </div>
            <div className="aside-items">
              <div className="host-div">
                <HostFollowed title ="Hosts you follow" hosts={hosts} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
export default Feed;

const Wrapper = styled.div `

.canvas{
  display:grid;
  grid-template-columns:18% 82%;
  position:relative;
  background:#03045E;
  @media screen and (max-width:768px){
    display:grid;
    grid-template-columns:1fr;
    grid-template-areas:
    "image"
    "items";
    background: #fff;
  }

  
  .aside-content{

    @media screen and (max-width:768px){
      grid-area:image;
    }
  }
  .main-content{
    border-top-left-radius:15px ;
    border-top-right-radius:5px ;
    border-bottom-left-radius:15px ;
    border-bottom-right-radius:0 ;
    margin-top:10px;
    background:#fff;

    @media screen and (max-width:768px){
      grid-area:items;
    }
    .search-div{
      display:flex;
      margin:28px 0 45px 22px;
      width:max-content;
      gap:15px;
      place-items:center;
      @media screen and (max-width:450px){
        flex-direction:column;
        margin:18px auto 15px auto;
        
      }
  
      div{
        border: 1px solid #FFFFFF33;
        box-shadow: 6px 6px 16px 4px #D1CDC780;
        padding:5px;
        border-radius:5px;
        width:max-content;
      }
      input{
        border:none;
        width:90px;
        transition: all 300ms ease-out;
        @media screen and (max-width:450px){
          width:140px;
        }
      }
      input:focus{
        outline:none;
        width:150px;
        @media screen and (max-width:450px){
          width:240px;
        }
      }
    }
    .activity-div{
      display:grid;
      grid-template-columns: 3fr 1fr;
      gap:10px;

      @media screen and (max-width:768px){
        grid-template-columns:1fr;
      }
    }

    .aside-items{
      
      .host-div{
        position:absolute;
        top:10px;

        @media screen and (max-width:768px){
          position:static;
          width:70%;
          margin:10px auto;
        }
      }
    }
    .current-viewing{
      border: 1px solid #FFFFFF33;
      box-shadow: 6px 6px 16px 4px #D1CDC780;
      width:120px;
      margin:24px 0;
    }
  }
 
}
`;