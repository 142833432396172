import reviewer1 from "./reviewer1.png";
import reviewer2 from "./reviewer2.png";
import reviewer3 from "./reviewer3.png";


const reviewerImages = {
  reviewer1,
  reviewer2,
  reviewer3,
}
export default reviewerImages;