import React from "react";
import styled from "styled-components";



const Follow = ({selected}) => {

  return (
    <Wrap>
      <div className="header">
        <h6>Follow Host</h6>
        <span onClick={() =>{selected("")}}>x</span>
      </div>
      <hr/>
      <p>Do you wish to follow this host and receive notifications from this host?</p>
      <div className="btn-div">
        <button className="no">No</button>
        <button className="yes">yes</button>
      </div>
    </Wrap>
  )
}
export default Follow;

const Wrap = styled.div `
  *{
    margin:0;
    padding:0;
    box-sizing:border-box;
  }
  width: 260px;
  border:2px solid #eee;
  padding: 5px;
  background:#ccc;
  text-align: center;

  p{
    font-size:14px;
    font-weight:500;
    text-align:center;
    margin: 20px 0 !important;
  }
  .header{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:4px;

    h6{
      font-size:12px;
      font-weight:800;
    }
    span{
      border-radius:50%;
      font-size:18px;
      font-weight:bold;
      padding:5px;
      color:red;
    }
  }
  .btn-div{
    display:flex;
    justify-content:space-between;
    margin:0 10px 5px 10px;
    gap: 25px;

    button{
      padding: 5px 10px;
      border-radius: 14px;
      width:50%;
      cursor:pointer;
      font-weight:800;
      border: 1px solid #0077B6;
    }
    .no:hover{
      background:red;
      border:1px solid red;
      color:#fff;
    }
    .yes:hover{
      background: #0077B6;
      color:#fff;

    }
  }
`;