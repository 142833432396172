import React from "react";
import { FaEye } from "react-icons/fa";
import styled from "styled-components";

 
const  RecentEvents = (props) => {

  const {eventData,title} = props;

  return(
    <Wrapper>
      <h5>{title}</h5>
      <div className="e-wrap">
        {
          eventData.map(e =>{
            return(
              <div className="events" key= {e.id}>
                <div className="img">
                  <img src={e.banner} alt="banner" />
                </div>
                <div className="details-div">
                  <p className="event-name">{e.programme.name}</p>
                  <p>{e.programme.date}</p>
                  <p className={e.status.toLowerCase() ==="occured"?"red":"blue"}>{e.status}</p>
                </div>
                <div className="viewed">
                  <FaEye />
                  <p>{e.participants}</p>
                </div>
              </div>
            )
          })
        }
      </div>
    </Wrapper>
  )
}
export default RecentEvents;

const Wrapper = styled.div `
  *{
    margin:0;
    padding:0;
    box-sizing:border-box;
  }
 
  
  padding:18px  10px 28px 12px;
  margin-bottom:5%;

  h5{
    font-weight:400;
    font-size:24px;
    
  }

  .e-wrap{
    margin:18px 0 10px 0;
  }
  .events{
    display:grid;
    grid-template-columns: 1fr 3fr 1fr;
    margin:10px 10px;

    .img{
      padding:4px;
      width:30%;
      img{
        width:120px;
        height:auto;
      }
    }
    .details-div{
      margin: 0 5px 15px 0;

      .event-name{
        font-size:15px;
        font-weight:bold;
        line-height:24px;
      }
      p{
        line-height:19px;
      }
      .red{
        color:red;
        cursor:pointer;
      }
      .blue{
        color:#0077b6;
        cursor:pointer;
      }
    }
    .viewed{
      margin-left:10px;
    }
    
  }
`;