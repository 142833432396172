import React from "react";
import styled from "styled-components";
import {FaCopyright} from "react-icons/fa";
import Images from "../../util/img/regImages";


const Footer = () => {

  return(
    <FooterWrapper>
      <div className="link-container">
        <div className="">
          <ul>
            <li>Event Feed</li>
            <li>Create Board</li>
            <li>Create Event</li>
            <li>Templates</li>
          </ul>
        </div>
        <div className="">
          <ul>
            <li>Watch Demo</li>
            <li>Solutions</li>
            <li>Frequently Asked Questions</li>
            <li>Pricing Plans</li>
          </ul>
        </div>
        <div className="social-icons-div">
          <p>Get connected with us via our social media handles</p>
          <div>
            <img src={Images.facebook} alt="social-media-icon" />
            <img src={Images.instagram} alt="social-media-icon" />
            <img src={Images.linkedin} alt="social-media-icon" />
            <img src={Images.twitter} alt="social-media-icon" />

          </div>
        </div>
      </div>
      <div className="privacy-row">
        <div className="">
          <p>Privacy Policy</p>
        </div>
        <div className="">
          <p><FaCopyright /> ElRoi 2021</p>
        </div>
        <div className="">
          <p>Terms of Service</p>
        </div>
      </div>
    </FooterWrapper>
  )
}
export default Footer;

const FooterWrapper = styled.div `
  background:var(--primaryColor);
  padding: 51px 90px 22px 89px !important;
  @media screen and (max-width:768px){
    padding:51px 25px 22px 25px !important;
  }
  color:#fff;
  font-family:"Roboto";
  ul{
    list-style:none;
    padding:0;
    li{
      margin:10px 0;
      font-size:14px;
    }
  }
  .link-container{
    display:grid;
    grid-template-columns: repeat(auto-fit,minmax(200px,1fr));
    .social-icons-div{
      margin:40px 0 0 0;

      img{
        width:20px;
        height:20px;
        margin: 3% 3% 3% 0 ;
        cursor:pointer;
      }
      p{
        font-size:18px;
      }
    }
  }
  .privacy-row{
    display:grid;
    grid-template-columns: repeat(auto-fit,minmax(200px,1fr));
    margin-top:3%;
    p{
      font-size:14px;
    }
  }
  @media screen and (max-width:780px){
    padding: 30px 21px 30px 21px;
    position: static;
  }
`