import React,{useState} from "react";
import styled from "styled-components";
import PrefImages from "../util/img/prefrence";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router";


const Preference = () => {

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState("Skip");
  const preference = [];
  
  const selectFeed = (item) =>{
    setStage("Finish")
    if(preference.includes(item)){
      preference.splice(preference.indexOf(item), 1);
      return;
    }
    preference.push(item);
  }

  const handleClick = (item) => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
      history.push(`${item}`)
    }, 4000);
  }
  return (
    <PreferenceWrap>
      <div className="side-div">
        <img src={PrefImages.pref} alt="art" />
      </div>
      <div className="icons-div">
        <h4>Want Personalized Feeds?</h4>
        <p className="text-c">Choose your interests and get personalized event feeds and suggestions.</p>
        <div className="pref-icons-div">
          <div className="pref-icon text-center">
            <input type="checkbox" onChange={()=>selectFeed("religion")}/>
            <img src={PrefImages.church} alt="art" />
            <p>Religion</p>
          </div>
          <div className="pref-icon text-center">
            <input type="checkbox" onChange={()=>selectFeed("business")}/>
            <img src={PrefImages.business} alt="art" />
            <p>Business</p>
          </div>
          <div className="pref-icon text-center">
            <input type="checkbox" onChange={()=>selectFeed("education")}/>
            <img src={PrefImages.edu} alt="art" />
            <p>Education</p>
          </div>
          <div className="pref-icon text-center">
            <input type="checkbox" onChange={()=>selectFeed("music")}/>
            <img src={PrefImages.music} alt="art" />
            <p>Music</p>
          </div>
          <div className="pref-icon text-center">
            <input type="checkbox" onChange={()=>selectFeed("media")}/>
            <img src={PrefImages.media} alt="art" />
            <p>Media</p>
          </div>
          <div className="pref-icon text-center">
            <input type="checkbox" onChange={()=>selectFeed("technology")}/>
            <img src={PrefImages.tech} alt="art" />
            <p>Technology</p>
          </div>
          <div className="pref-icon text-center">
            <input type="checkbox" onChange={()=>selectFeed("food")}/>
            <img src={PrefImages.food} alt="art" />
            <p>Food</p>
          </div>
          <div className="pref-icon text-center">
            <input type="checkbox" onChange={()=>selectFeed("health")}/>
            <img src={PrefImages.health} alt="art" />
            <p>Health</p>
          </div>
          <div className="pref-icon text-center">
            <input type="checkbox" onChange={()=>selectFeed("career")}/>
            <img src={PrefImages.career} alt="art" />
            <p>Career</p>
          </div>
          <div className="pref-icon text-center">
            <input type="checkbox" onChange={()=>selectFeed("arts")}/>
            <img src={PrefImages.art} alt="art" />
            <p>Arts</p>
          </div>
          <div className="pref-icon text-center">
            <input type="checkbox" onChange={()=>selectFeed("fashion")}/>
            <img src={PrefImages.fashion} alt="art" />
            <p>Fashion</p>
          </div>
          <div className="pref-icon text-center">
            <input type="checkbox" onChange={()=>selectFeed("charity")}/>
            <img src={PrefImages.charity} alt="art" />
            <p>Charity</p>
          </div>
        </div>
        {loading ===true ?<Loader type="ThreeDots" />:
          <>
            <div className="btn-div">
            <button className="light-btn" onClick={()=>handleClick("/welcome")}>Back</button>
            <button className="dark-btn" onClick={() =>handleClick("/user/dashboard")}>{stage}</button>
            </div>
          </>
        }
      </div>
    </PreferenceWrap>
  )
}
export default Preference;

const PreferenceWrap = styled.div `
  display:flex;
  gap:1px;
  place-items: center;


  .side-div{
    margin:0:
    padding:10% 53px 59px 92px;
  }

  .icons-div{
    background:#eee;
    padding:26px 51px 52px 53px;
    width:100%;
  }
  .icons-div h4{
    font-size:24px;
    font-weight:800;
    line-height:37px;
  }
  .icons-div .text-c{
    font-size:21px;
    font-weight:400;
    line-height:24px;
    margin: 20px 0 1  0px
  }
  .icons-div .pref-icons-div{
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    grid-column-gap:15px;
    grid-row-gap:15px;
  }
  .icons-div .pref-icons-div input{
    position:absolute;
    top:20px;
    left:8px;
  }
  .icons-div .pref-icons-div .pref-icon{
    position:relative;
    width:80%;
    height:100px;
    margin:0 auto;
    background: #FFFFFF;
    padding:22px 49px 20px 53px;
    border: 0.5px solid rgba(79, 79, 79, 0.21);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
    text-align: center;
    @media screen and (max-width:450px){
      width:70%;
      padding:5px;
    }

    img{
      width:100%;

      @media screen and (max-width:450px){
        width:41%;
      }
    }
  }

  .btn-div{
    margin:40px auto 10px auto;
  }
  .btn-div .light-btn{
    margin:0 70px 0 131px;
    border-radius: 10px;
    background:#4F4F4F73;
    border: 1px solid #4F4F4F73;
    font-size:30px;
    width:205px;
  }
  .btn-div .dark-btn{
    border-radius: 10px;
    background: var(--btnColor);
    border: 1px solid var(--btnColor);
    color:#fff;
    font-size:30px;
    width:205px;
  }
  @media screen and (max-width:980px){
    .side-div{
      margin:0:
      padding:79px 13px 79px 42px;
      img{
        width:95%;
      }
    }
    .icons-div{
      padding:6px 11px;
    }
    .icons-div h4{
      font-size:24px;
      font-weight:700;
      line-height:27px;
      width:300px
    }
    .icons-div .text-c{
      font-size:14px;
      line-height:18px;
      margin: 10px 0;
      width:300px;
    }

    .icons-div .pref-icons-div{
      grid-column-gap:8px;
      grid-row-gap:11px;
    }

    .icons-div .pref-icons-div .pref-icon{
      background: #FFFFFF;
      padding:12px 8px 10px 13px;
      margin:0 auto;
      img{
        width:40%;
      }
    }

    .btn-div{
      margin:10px auto;
      display:flex;
    }
    .btn-div .light-btn{
      margin:20px 5px;
      border-radius: 10px;
      width:50%;
    }
    .btn-div .dark-btn{
      width:50%;
      margin:20px 5px;
    }
  }
  @media screen and (max-width:768px){
    flex-direction: column;
  }

`;