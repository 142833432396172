import React from "react";
import styled from "styled-components";
import Header from "../components/Layout/MainLayout";
import Footer from "../components/Footer";
import Images from "../util/img/homeImg"



const About = () => {

  return (
    <AboutWrapper>
      <Header>
        <div className="about-contents">
          <div className="sample-text">
            <h4>Lorem Ipsum is simply dummy text.</h4>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
              Lorem Ipsum has been the industry's standard.
            </p>
          </div>
          <div>
            <div className="digital">
              <h4>Our Digital Approach</h4>
              <p>
              Lorem Ipsum is simply dummy text of the printing.
              </p>
            </div>
            <div>
              <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
              when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
              </p>
            </div>
          </div>
        </div>
        <div className="features">
          <div className="title">
            <h4>Our Features</h4>
            <p>Lorem Ipsum is simply dummy text of the printing.</p>
          </div>
          <div className="item-sample-div">
            <div className="item-img" id="upload">
              <img src={Images.upload} alt=""/>
              <h6>Upload or Download Files</h6>
              <p>Lorem Ipsum is simply dummy text of the printing.</p>
            </div>
            <div className="item-img" id="analytic">
              <img src={Images.analytic} alt=""/>
              <h6>Event Analytics</h6>
              <p>Lorem Ipsum is simply dummy text of the printing.</p>
            </div>
          </div>
          <div className="item-sample2-div">
            <p>Lorem Ipsum is simply dummy text of the printing that 
              can be used for your designs.
            </p>
            <div className="item-container">
              <div className="item-img" id="event">
                <img src={Images.feed} alt=""/>
                <h6>Event Feeds</h6>
                <p>Lorem Ipsum is simply dummy text of the printing.</p>
              </div>
              <div className="item-img" id="content">
                <img src={Images.content} alt=""/>
                <h6>Custom Made Content</h6>
                <p>Lorem Ipsum is simply dummy text of the printing.</p>
              </div>
            </div>
          </div>
          <div className="hotspot">
            <p>Lorem Ipsum is simply dummy text of the printing.</p>
            <img src={Images.wifi} alt=""/>
          </div>
        </div>
      </Header>
      <Footer />
    </AboutWrapper>
  )
}
export default About;

const AboutWrapper = styled.div `
  *{
    margin:0;
    padding:0;
    box-sizing: border-box;
  }
  h4, h6{
    font-family: "Gilroy ExtraBold";
  }
  h4{
    font-size:28px;
    @media screen and (max-width:450px){
      font-size:21px;
      width:90%;
    }
  }
  h6{
    font-size:24px;
    @media screen and (max-width:450px){
      font-size:16px;
      width:90%;
    }
  }
  p{
    font-family: "Roboto ";
    font-size:22px;
    font-weight:400;
    line-height:26px;
    @media screen and (max-width:450px){
      font-size:18px;
    }
  }
  .about-contents{
    margin-top:15%;
    padding:0 71px;
    @media screen and (max-width:450px){
      padding:0 10px;
      margin-top:10%;
    }

    .sample-text{
      margin-bottom:15%;
      p{
        width:45%;
        margin:19px 0;
        @media screen and (max-width:450px){
          width:100%;
          margin:10px auto;
        }
      }
    }
    .digital{
      width:30%;
      margin: 26px auto;
      text-align: center;
      @media screen and (max-width:768px){
        width:80%;
      }
      @media screen and (max-width:450px){
        width:100%;
      }
    }
    .digital ~div{
      width: 50%;
      margin:7% 0 13% 0;
      @media screen and (max-width:768px){
        width:100%;
      }
    }
  }

  .features{
    background: #CAF0F87D;
    padding:50px 71px;
    position: relative;
    @media screen and (max-width:450px){
      padding:40px 10px;
    }

    .title{
      text-align: center;
      p{
        width:30%;
        margin:10px auto;
        @media screen and (max-width:768px){
          width:80%;
        }
        @media screen and (max-width:450px){
          width:100%;
        }
      }
    }
    .item-sample-div{
      display:flex;
      width:75%;
      margin-left:auto;
      margin:40px 0 40px auto;
      gap:20px;
      position:relative;
      @media screen and (max-width:768px){
        width:100%;
        gap:10px;
      }
      @media screen and (max-width:450px){
        flex-direction:column;
      }

      .item-img{
        text-align:center;
        max-width:350px;
        padding:5px;
        @media screen and (max-width:450px){
          max-width:300px;
          padding:10px;
        }
        img{
          width:290px;
          height:290px;
          background:#fff;
          padding:10px;
          border-radius:5px;
          box-shadow: 6px 6px 16px 4px #D1CDC780;
          @media screen and (max-width:450px){
           width:240px;
           height:250px;
          }
        }
        h6{
          margin:10px 0;
          font-size:20px;
        }
      }
      #analytic{
        margin-left:24%;
        @media screen and (max-width:768px){
          margin-left:5%;
        }
        @media screen and (max-width:450px){
          margin-left:0;
        }
      }
    }
    .item-sample2-div{
      position:relative;
      margin: 10% 0;
      

      p{
        width:28%;
        @media screen and (max-width:768px){
          width:100%;
        }
      }
      .item-img{
        text-align:center;
        padding:15px;
        width:45%;
        @media screen and (max-width:450px){
          width:100%;
        }
        img{
           width:290px;
          height:290px;
          background:#fff;
          padding:10px;
          border-radius:5px;
          box-shadow: 6px 6px 16px 4px #D1CDC780;
          @media screen and (max-width:450px){
           width:240px;
           height:250px;
          }
        }
        h6{
          margin:10px 0;
          font-size:20px;
        }
        p{
          width:60%;
          margin:0 auto;
          @media screen and (max-width:768px){
            width:100%;
          }
        }
      }
      .item-container{
        @media screen and (max-width:768px){
          display:flex;
          justify-content:space-between;
        }
        @media screen and (max-width:450px){
          flex-direction:column;
        }
      }
      #event{
        position:absolute;
        right:0;
        top:10px;
        @media screen and (max-width:768px){
          position:static;
        }
      }
      #content{
        position:relative;
        left:18%;
        margin-top:10%;
        @media screen and (max-width:768px){
          position:static;
          left:0;
          margin-top:0;
        }
      }
    }
    .hotspot{
      width:25%;
      img{
        margin:10% 0 5% 0;
      }
      @media screen and (max-width:768px){
       width:100%;
      }
    }
  }
  
  

`;