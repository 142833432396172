import React from "react";
import styled from "styled-components";
import StreamLayout from "../components/Layout/StreamLayout";
import ReactPlayer from 'react-player';



const Stream = () =>{

  return(
    <StreamLayout title="Abirs Alliance Quarterly Meeting">
      <Wrap >
        {/* <img src={LayoutImages.news} alt="news" /> */}
        <ReactPlayer 
          url='https://www.youtube.com/watch?v=96OrBX1epXM'
          
          width="100%"
          height="550px"
          controls={true}
          volume={1}
          
          
        />
        {/* <video controls width="100%">

          <source src="https://www.youtube.com/watch?v=96OrBX1epXM"
                  type="video/mp4"/>

          Sorry, your browser doesn't support embedded videos.
        </video> */}
      </Wrap>
      
    </StreamLayout>
  )
}
export default Stream;

const Wrap = styled.div `

`;