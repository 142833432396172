import profile1 from "./profile1.png";
import profile2 from "./profile2.png";
import profile3 from "./profile3.png";
import profile4 from "./profile4.png";
import profile5 from "./profile5.png";
import profile6 from "./profile6.png";
import profile7 from "./profile7.png";
import profile8 from "./profile8.png";
import profile9 from "./profile9.png";
import profile10 from "./profile10.png";
import profile11 from "./profile11.png";
import profile12 from "./profile12.png";
import profile13 from "./profile13.png";




const profileImg ={
  profile1,
  profile2,
  profile3,
  profile4,
  profile5,
  profile6,
  profile7,
  profile8,
  profile9,
  profile10,
  profile11,
  profile12,
  profile13,
}
export default profileImg;