import church from "./church.svg";
import business from "./business.svg";
import pref from "./pref.png";
import career from "./career.png";
import charity from "./charity.svg";
import art from "./art.svg";
import edu from "./education.svg";
import fashion from "./fashion.svg";
import food from "./food.svg";
import health from "./health.svg";
import music from "./music.svg";
import media from "./media.svg";
import tech from "./tech.svg";


const PrefImages = {
    church,
    business,
    pref,
    career,
    charity,
    art,
    edu,
    fashion,
    food,
    health,
    music,
    media,
    tech,
}
export default PrefImages;