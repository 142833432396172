import logo from "./logo.png";
import lock from "./lock.svg";
import eroi from "./ElRoi.png";
import news from "./news.png";
import folder from "./Folder.png";
import excel from "./excel.png";
import powerpoint from "./powerpoint.png";
import powerpoint1 from "./powerpoint2.png";
import word from "./word.png";
import pdf from "./pdf.png";



const LayoutImages = {
  logo,
  lock,
  eroi,
  news,
  folder,
  excel,
  powerpoint,
  powerpoint1,
  word,
  pdf,
}
export default LayoutImages;