import React from "react";
import styled from "styled-components";
import Header from "../components/Layout/MainLayout";
import Footer from "../components/Footer";



const Solution = () => {


  return(
    <Wrapper>
      <Header>
        <div className="wrapper-div">
          <h4>For Your Religious Events</h4>
          <div className="flex-div">
            <div className="img-div"></div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
              Lorem Ipsum has been the industry's standard.
            </p>
          </div>
          <div className="flex-div">
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
              Lorem Ipsum has been the industry's standard.
            </p>
            <div className="img-div"></div>
          </div>
          <div className="flex-div">
            <div className="img-div"></div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
              Lorem Ipsum has been the industry's standard.
            </p>
          </div>
          <button id="start">GET STARTED</button>
        </div>
        <div className="text-wrap">
          <div className="first-text">
            <h4>For Your Regular Events</h4>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
              Lorem Ipsum has been the industry's standard.
            </p>
          </div>
          <div className="second-text">
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
              Lorem Ipsum has been the industry's standard.
            </p>
          </div>
        </div>
      </Header>
      <Footer />
    </Wrapper>
  )
}
export default Solution;

const Wrapper = styled.main `
  *{
    margin:0;
    padding:0;
    box-sizing:border-box;
  }

  .wrapper-div{
    background: #F5F5F5;
    padding:20px 25px 20px 85px;
    text-align:center;
    @media screen and (max-width:768px){
      padding: 5px 10px 20px 35px;
    }
    @media screen and (max-width:450px){
    }
    h4{
      text-align:center;
      margin: 18% auto 0 auto;
      font-family: "Gilroy ExtraBold";
      font-size: 30px;
      font-weight: 800;
      line-height: 37px;
      @media screen and (max-width:768px){
        margin: 14% auto 6% auto;
      }
      @media screen and (max-width:450px){
        font-size:21px;
        width:80%;
        font-weight: 600;
        line-height: 25px;
        margin: 4% 5px 0 5px;
      }
    }

    .flex-div{
      display:flex;
      gap:9%;
      align-items:center;
      margin:2% 0 4% 0;
      @media screen and (max-width:450px){
        flex-direction:column;
        margin:4% 0 4% 0;
      }

      .img-div{
        height:250px;
        width:260px;
        border-radius:5px;
        background:#fff;
         box-shadow: 6px 6px 16px 4px #D1CDC780;
      }
      p{
        width:50%;
        font-family: "Roboto";
        font-size: 22px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        @media screen and (max-width:450px){
          width:90%;
          margin:5px auto 15px auto;
        }
      }
    }
    #start{
      background: #0077B6;
      box-shadow: 1px 1px 1px 0px #0077B65C;
      height: 65px;
      width: 268px;
      border-radius: 11px;
      border:none;
      color:#fff;
      margin:6% auto !important;
      font-family:"Gilroy ExtraBold";
      font-size:24px;
      cursor:pointer;
    }
  }
  
  .text-wrap{
    padding:20px 25px 20px 85px;
    @media screen and (max-width:768px){
      padding: 5px 10px 20px 35px;
    }
    .first-text{
      width:30%;
      @media screen and (max-width:768px){
        width:45%;
      }
      margin:3% 9% 7% auto;
      @media screen and (max-width:450px){
        width:95%;
        margin:3% auto 7% auto;
      }

      h4{
        margin-bottom: 40px;
        @media screen and (max-width:450px){
          margin-bottom:25px;
        }
      }
    }
    .second-text{
      width: 30%;
      @media screen and (max-width:768px){
        width:45%;
      }
       @media screen and (max-width:450px){
        width:95%;
        margin:0 auto 7% auto;
      }
      margin:0 0 5% 0
    }
    .first-text, .second-text{
      font-family: "Roboto";
      font-size: 22px;
      font-weight: 400;
      line-height: 28px;
    }
  }
  
`;