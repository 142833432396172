import React,{useState} from "react";
import styled from "styled-components";
import {FaBell, FaCaretDown,  } from "react-icons/fa";
import {IoSettingsSharp, IoMenuSharp} from "react-icons/io5";
import logo from "./logo.png";
import {Link, NavLink  } from "react-router-dom";

const Navs = (props) =>{


  const [active, setActive] = useState('none');
  const handleActive = (item) =>{
    console.log(item);
    if(active ==="none"){
      setActive("");
      return;
    }
    setActive(item);
  }
  return(
    <Wrap>
      <div className="nav-wrapper">
        <img src={logo} alt="logo"  className="logo"/>
        <div className="user-nav-items">
          <NavStyles className={active ==="none"?"hide":""}>
            <NavLink to="/user/dashboard"  activeClassName="current">
              <li>Feeds</li>
            </NavLink>
            <NavLink to="/user/dashboard/overview" activeClassName="current"> 
              <li>Overview</li>
            </NavLink>
            <NavLink to="/user/dashboard/analytics" activeClassName="current">
              <li>Analytics</li>
            </NavLink>
            <NavLink to="/user/dashboard/board" activeClassName="current">
              <li>Board</li>
            </NavLink>
            <NavLink to="/user/dashboard/event" activeClassName="current" >
              <li>Events</li>
            </NavLink>
          </NavStyles>
          <div className="userprofile">
            <IoSettingsSharp className="icon"/>
            <FaBell className="icon"/>
            <Link to="/user/dashboard/profile">
              <p>{props.username}  <FaCaretDown className="icon"/></p>
            </Link>
            <img src={props.profileImg} alt="profile" />
          </div>
          <IoMenuSharp className="menu-icon" onClick={()=>handleActive("none")}/>
        </div>
      </div>
      <div>{props.children}</div>
    </Wrap>
  )
}
export default Navs;

const NavStyles = styled.ul `
  display: flex;
  align-items:center;
  list-style:none;
  margin-left:auto;

  @media screen and (max-width:600px){
    flex-direction:column;
  }

a {
  text-decoration: none;
}

li {
  margin: 0 0.8rem;
  font-size: 1.1rem;
  position: relative;
  list-style: none;
}

.current {
  li {
    color: #fff;
  }
}
`
const Wrap = styled.div `
  *{
    padding:0;
    margin:0;
    box-sizing:boarder-box;
  }
  .nav-wrapper{
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap:86px;
    background: #03045E;
    color:#fff;
    padding: 5px;
  
    @media screen and (max-width:600px){
    gap:20px;
    }
  }


 .logo{
   width:80px;
   margin-top:10px;
   margin-left:44px;

   @media screen and (max-width:600px){
     width:40px;
     margin:18% 0 5px 5px;
   }
 }
 .icon{
   cursor:pointer;
 }
  .user-nav-items{
    display: grid;
    grid-template-columns: 2fr 1fr;
    width:100%;
    gap:20px !important;
    margin-left:auto;

    

    .userprofile{
      display:flex;
      align-items:center;
      justify-content:space-between;
      margin-left:auto;
      gap:10px;

      a{
        text-decoration:none;
        color:#fff;
      }
      img{
        border-radius:50%;
      }
    }
    .menu-icon{
      display:none;
    }

    @media screen and (max-width:600px){
      grid-template-areas: "profile profile profile menu menu";
      gap: 1px !important;
      position:relative;
  
      ul{
        grid-area: menu;
        position:absolute;
        background:#03045E;
        width:100px !important;
        transition: all 3s ease;
        top: 79%;
        right: -1px;
        z-index:99;

        li{
          display:block;
        }
      }
      ul.hide{
        transition:2s ease;
        width:0;
        display:none;
      }
    
      .userprofile{
        grid-area:profile;
        display:flex;
        align-items:center;
        justify-content:space-between;
        margin-left:auto;
        gap:10px;
        width:100%;
       
        img{
          width:50%;
        }
      }
      .menu-icon{
        display:block;
        width: 40px;
        font-size:28px;
        margin-left:auto;
        margin-top:5px;
        grid-area: menu;
      }
    }
    
    @media screen and (max-width: 768px){
      ul{
        width:100%;
        margin:14px 0;
        li{
          padding:3px 5px;
          margin: 5px;
          font-size: 10px;
        }
      }
      .userprofile{
        display:flex;
        align-items:center;
        justify-content:space-between;
        gap:3px;
        font-size:10px;

        img{
          width:20%;
        }
      }
    }
  }
 
  
`;