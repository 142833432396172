import React from "react";
import styled from "styled-components";
import AsideSection from "./AsideSection";
import { FaSearch, FaCaretDown } from "react-icons/fa";
import { recentEvents, reviewData } from "../../Data/data";
import RecentEvents from "./AnalyticComponents/RecentEvent";
import EventReview from "./AnalyticComponents/EventReview";
import EventChart from "./AnalyticComponents/EventChart";



const Analytics = (props) => {

  const { asidImg, text } = props;

  return (
    <Wrap>
      <div className="canvas">
        <div className="aside-content">
          <div className="aside-div">
            <AsideSection image={asidImg} text={text} />
          </div>
        </div>
        <div className="main-content">
          <div className="search-div">
            <div>
              <FaSearch />
              <input type="text" placeholder="search" />
            </div>
            <p>Filter</p>
            <div>
              <input type="text" placeholder="last 30 days" />
              <FaCaretDown />
            </div>
          </div>
          <div className="activity-div">
            <div >
              <div className="chart-div">
                <h5>Event Overview</h5>
                <EventChart />
              </div>
              <div className="all-time-stat">
                <p>All time</p>
                <div className="wrap-div">
                  <div className="bordered">
                    <h4>300</h4>
                    <p>Registered</p>
                  </div>
                  <div className="bordered">
                    <h4>250</h4>
                    <p>Attended</p>
                  </div>
                  <div>
                    <h4>150</h4>
                    <p>Reviews</p>
                  </div>
                </div>
              </div>
              <div className="review">
                <EventReview title="Your Event Reviews" reviewData={reviewData} />
              </div>
            </div>
            <div className="aside-items">
              <div>
                <div className="host-stat-div">
                  <div className="view">
                    <h4>300</h4>
                    <p>Views</p>
                  </div>
                  <div className="likes">
                    <h4>100</h4>
                    <p>Likes</p>
                  </div>
                  <div className="follow">
                    <h4>200</h4>
                    <p>Followers</p>
                  </div>
                </div>
                <div className="recents-event">
                  <RecentEvents eventData={recentEvents} title="Your Recent Events" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </Wrap>
  )
}
export default Analytics;

const Wrap = styled.div`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .canvas{
    display:grid;
    grid-template-columns:18% 82%;
    position:relative;
    background:#03045E;
    @media screen and (max-width:768px){
      display:grid;
      grid-template-columns:100%;
      grid-template-areas:
      "image"
      "items";
      background: #fff;
      width:100% !important;
      margin:0 auto;
    }
  
    
    .aside-content{
  
      @media screen and (max-width:768px){
        grid-area:image;
      }
      div.aside-div{
      }
    }
    .main-content{
      border-top-left-radius:15px ;
      border-top-right-radius:5px ;
      border-bottom-left-radius:15px ;
      border-bottom-right-radius:0 ;
      margin-top:10px;
      background:#fff;
  
      @media screen and (max-width:768px){
        grid-area:items;
      }
      .search-div{
        display:flex;
        margin:28px 0 45px 22px;
        width:max-content;
        gap:15px;
        place-items:center;
        @media screen and (max-width:450px){
          flex-direction:column;
          margin:18px auto 15px auto;
          
        }
    
        div{
          border: 1px solid #FFFFFF33;
          box-shadow: 6px 6px 16px 4px #D1CDC780;
          padding:5px;
          border-radius:5px;
          width:max-content;
        }
        input{
          border:none;
          width:90px;
          transition: all 300ms ease-out;
          @media screen and (max-width:450px){
            width:140px;
          }
        }
        input:focus{
          outline:none;
          width:150px;
          @media screen and (max-width:450px){
            width:240px;
          }
        }
      }
      .activity-div{
        display:grid;
        grid-template-columns: 1.5fr 1fr;
        gap:5px;
  
        @media screen and (max-width:768px){
          grid-template-columns:100% ;
        }

        .chart-div{
          border: 1px solid #FFFFFF33;
          box-shadow: 6px 6px 16px 4px #D1CDC780;
          border-radius:11px;
          padding:8px 14px 18px 14px;
          margin:8px 21px 23px 21px;

          @media screen and (max-width:450px){
            width:100%;
            margin: 5px auto 10px auto !important;
            overflow-x: auto;
          }
        }
        .all-time-stat{
          border-radius:11px;
          background:#0077b6;
          box-shadow: 6px 6px 16px 4px #D1CDC780;
          margin:17px 25px 17px 15px;
          padding:15px;
          height:130px;
          color:#fff;

          @media screen and (max-width:450px){
            margin:10px auto;
          }
          p{
            font-size: 24px;
          }

          .wrap-div{
            display:flex;
            gap:5px;
            height: 53px;
            align-items:center;
            margin:0 49px;
            justify-content:space-between;

            @media screen and (max-width:450px){
              margin:2px;
            }
            div{
              text-align: center;
              width: 60%;
              padding:0 20px;
              height:65px;
              margin:5px 0 20px 0;
              @media screen and (max-width:450px){
                margin:2px !important;
                padding:5px;

                h4{
                  margin-top:0;
                }
                p{
                  font-size:18px;
                  color:#FFFFFF9E !important;
    
                }
              }

              h4{
                margin-top:5px;
              }
              p{
                font-size:18px;
                color:#FFFFFF9E !important;
  
              }
            }
            div.bordered{
              border-right:2px solid #ccc;
              
            }
           
          }
        }
        
        .review{
          border: 1px solid #FFFFFF33;
          border-radius:11px;
          box-shadow: 6px 6px 16px 4px #D1CDC780;
          margin:17px 25px 17px 15px;
        }
      }
  
      .aside-items{
        .host-stat-div{
          display:flex;
          gap:16px;
          margin:10px 33px 11px 0;
          
        @media screen and (max-width:450px){
          flex-direction:column;
          margin:5px auto;
          align-items:center;
        }
        @media screen and (max-width:768px){
          display:flex;
          gap:16px;
          margin:10px 33px 11px 0;
        }

          .likes, .follow, .view{
            color:#fff;
            width:130px;
            height:150px;
            border-radius:11px;
            text-align:center;
            padding:15% 31px 25% 31px;
            box-shadow: 6px 6px 16px 4px #D1CDC780;
            transition: 1s;
            cursor:pointer;

            @media screen and (max-width:450px){
              width:80%;
            }
            @media screen and (max-width:768px){
              padding:5% 31px 10% 31px;
              width:180px;
              margin: 0 auto;
              height:100px;
            }

            h4{
              margin-bottom:12px;
            }
          }
          .likes{
            background: linear-gradient(216.89deg, #080AD2 -21.46%, #141417 14.07%, #202063 86.89%);
          }
          .likes:hover{
            transform:scale(1.2);
            background: linear-gradient(216.89deg, #202063 -21.46%, #9c85dbde 14.07%,  #080AD2 86.89%);
            @media screen and (max-width:450px){
              transform:scale(1) !important;
            }
          }
          .follow{
            background: linear-gradient(220.02deg, #216283 6.12%, #32BBFF 96.88%, #32BBFF 96.88%);
          }
          .follow:hover{
            transform:scale(1.2);
            background: linear-gradient(220.02deg, #9c85dbde 6.12%, #32BBFF 96.88%, #9c85dbde 96.88%);
            @media screen and (max-width:450px){
              transform:scale(1) !important;
            }
          }
          .view{
            background: linear-gradient(232.51deg, #0176B4 -1.68%, #143648 40.1%, #1A1F21 72.97%);
          }
          .view:hover{
            transform:scale(1.2);
            background: linear-gradient(to right, #f64f59, #c471ed, #12c2e9);
            @media screen and (max-width:450px){
              transform:scale(1) !important;
            } 
          }
         
        }
        
        .recents-event{
          margin:27px 33px 31px 0;
          padding:5px;
          border-radius:11px;
          border: 1px solid #FFFFFF33;
          box-shadow: 6px 6px 16px 4px #D1CDC780;
        }
      }
      
    }
   
  }
`;