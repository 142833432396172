import React,{useState, useEffect} from "react";
import styled from "styled-components";
import Welcome from "../components/Welcome";




const WelcomePage = () => {


  const [user, setUser] = useState("");

  useEffect(() =>{
    setUser(sessionStorage.getItem("user"));
  },[user])

  return(
    <Wrap>
      {user !== "" && <Welcome user={user}/>}
    </Wrap>
  )
}
export default WelcomePage;

const Wrap = styled.div `

`;