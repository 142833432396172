import React from "react";
import styled from "styled-components";




const HostFollowed = (props) => {
  const {title, hosts}  = props;
  return(
    <Wrap>
      <div className ="hosts-div">
        <h5>{title}</h5>
        {
          hosts.map(host =>{
            return(
              <div className="host-container" key={host.id}>
                <img src={host.banner} alt="host icon"/>
                <h6>{host.name}</h6>
                {host.has_event === true ? <p>live</p>: null}
              </div>
            )
          })
        }
      </div>
    </Wrap>
  )
}
export default HostFollowed;

const Wrap = styled.div `
  *{
    margin:0;
    padding:0;
    box-sizing:border-box;
  }
  .hosts-div{
    box-shadow: 6px 6px 16px 4px #D1CDC780;
    border: 1px solid #FFFFFF33;
    padding:15px 5px 27px 15px;
    background:#fff;

    @media screen and (max-width:768px){
      margin 4% auto;
      width:95%;
      border-radius:11px;
    }
    @media screen and (max-width:450px){
      margin 4% auto;
      width:90%;
      border-radius:11px;
    }
    .host-container{
      display:flex;
      gap:5px;
      padding:10px 5px;
      align-items:center;

      img{
        width:17%;
      }
      p{
        color:red;
        cursor:pointer;
        @media screen and (max-width:768px){
          margin-left:3%;
        }
      }
      h6{
        font-size:14px;
      }
    }
  }
`;