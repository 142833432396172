import React from "react";
import {FaFileAlt } from "react-icons/fa";
import styled from "styled-components";


const Uploads = () => {

  return(
    <Wrapp>
      <div className="dismiss-div">
        <h6>Uploads</h6>
        <span className="close">x</span>
      </div>
      <FaFileAlt className="icon"/>
      <p className="browse-file">When the host uploads documents, you can download here.t</p>
      
    </Wrapp>
  )
}
export default Uploads;

const Wrapp = styled.div `
  *{
    margin:0;
    padding:0;
    box-sizing:border-box;
  }
  border:2px solid #eee;
  background:#ffffffab;
  padding:5px 20px;
  color:#000;
  width: 240px;

  .icon{
    font-size: 4em !important;
    margin: 20px auto 15px auto;
  }
  p{
    font-size: 12px;
    margin: 15px 0 !important;
  }
  .dismiss-div{
    display:flex;
    justify-content:space-between;
    font-size:18px;
    align-items: center;
    margin-bottom: 5px;

    span{
      color: red !important;
      font-weight:600;
      cursor:pointer;
    }
  }
`;