import React,{useState} from "react";
import styled from "styled-components";
import Loader from "react-loader-spinner";



const JoinEventForm = (props) => {

  const {CancelJoin} = props;
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [erroMsg,setErrorMsg] = useState("");

  const handleSubmit = () => {

    setErrorMsg("");
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      if(id ==="" || password ===""){
        console.log(id,password);
        setErrorMsg("Please Enter Event Details.");
        return;
      }
      setErrorMsg("");
      window.location.href="/client-streaming";
    }, 5000);
  }

  return(
    <Wrap>
      <div className={"event-form"}>
        <span onClick={()=>CancelJoin()}>x</span>
        <h4>Fill in the event id and password to attend your event.</h4>
        <form onSubmit={(e) =>e.preventDefault()}>
          <p className="error">{erroMsg}</p>
          <div>
            <label htmlFor="id">EventId</label><br/>
            <input type="text" name="id" required onChange={(e) =>setId(e.target.value)}/>
          </div>
          <div>
            <label htmlFor="password">Password</label><br/>
            <input type="password" name="password" required onChange={(e) =>setPassword(e.target.value)}/>
          </div>
          {loading !== true ?<button type="submit" className="btn-join" 
              onClick={() =>handleSubmit()}
            >JOIN EVENT</button>:
            <Loader className="load" type="ThreeDots" color="#0077b6"/>
          }
        </form>
      </div>
    </Wrap>
  )
}
export default JoinEventForm;

const Wrap = styled.div `
  *{
    padding:0;
    margin:0;
    box-sizing:boarder-box;
  }

 

  .event-form{
    border: 1px solid #ccc;
    background:#eee;
    width: 450px;
    margin: 5px auto;
    padding:25px 15px;
    position:absolute;
    top:20%; 
    left:30%;
    z-index:999;
  
    span{
      color: red !important;
      font-weight:700;
      cursor:pointer;
      font-size:18px;
      position:absolute;
      right:10px;
      top:5px;
    }
    .error{
      color:red;
      margin:5px 0;
    }
    form{
      margin:14px auto;
      padding:20px;

      input{
        border: 1px solid #C4C4C4;
        box-shadow: 0px 2px 2px 0px #00000017;
        border-radius:8px;
        margin-bottom:20px;
        padding:5px;
        width:100%;
      }
      input:focus{
        outline:none;
      }
      .btn-join{
        background:var(--btnColor);
        color:#fff;
        font-weight:700;
        margin-top:10px;
        width:100%;
        height:45px;
        border:none;
        transition: 4s easeout;
      }
      .btn-join:hover{
        border-radius:10px;
        background:#023e5ede;
      }
      .load{
        margin:0;
        text-align:center;
        height:40px;
      }
    }
    @media screen and (max-width:600px){
      width:80%;
      margin: 2% auto;
      top:50%;
      left:20px;
    }
    
  }
`
