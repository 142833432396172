import React from "react";
import styled from "styled-components";
import Header from "../components/Layout/MainLayout";



const HowItWorks = () => {


  return(
    <Wrapper>
      <Header>
        
      </Header>
    </Wrapper>
  )
}
export default HowItWorks;

const Wrapper = styled.main `

`;