import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import dropdown from "./dropDown.svg";
import {FaArrowLeft, FaCaretRight, FaImage, FaInfoCircle, FaPlus, FaQuestionCircle} from "react-icons/fa";
import Toggler from "../../Toggler";
import Overlay from "../../Overlay";
import {useForm} from "react-hook-form";
import Axios from "axios";
import Loader from "react-loader-spinner";



const CreateEvent = ({categories}) =>{

  const [venue, setVenue] = useState("online");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [backgroundImgUrl, setBackgroundImgUrl] = useState('');
  // const [titles, setTitle] = useState('');
  // const [desc, setDesc] = useState('');
  
  const handleVenue = (option) =>{
    setVenue(option);
  }
  
  const {register, handleSubmit, formState:{errors},} = useForm();

  const handleImgChange = (e) =>{
    let reader = new FileReader();
    let file  = e.targert.files[0];
    reader.onloadend =  () =>{
      setBackgroundImgUrl(reader.result);
    }
    reader.readAsDataURL(file);
    console.log(e.target.files[0]);
  }
  const addBgImg = () =>{
    const img = document.getElementById("event-banner");
    img.click();
  }
  const config = {
    headers: { 
      'token': `${sessionStorage.getItem('token')}`, 
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`, 
      'Content-Type': 'application/json'
    },
  };
  const publishEvent = (data) =>{
    setMsg("");
    setLoading(true);

    let startTime = data.sessionStart;
    let stopTime = data.sessionEnd;
    if(startTime >= stopTime){
      setMsg('Error: session start time should be earlier than session stop time.');
      setLoading(false);
      return;
    }
    let eventDetails ={
      title: data.title,
     description:data.description,
      categoryId: data._id,
      startDate: data.date,
      endDate: data.endTime,
      location: {
        coordinates: [
          30,
          40.0
        ]
      },
      attendanceLimit: data.participantLimit,
      requirePassword: false
    }

    console.log(eventDetails);
    return Axios.post("https://elroi.live/api/v1/events",eventDetails,config)
      .then((res) =>{
        setMsg("Event Created Successfully.");
        setTimeout(() => {
        setLoading(false);
        }, 1000);
      })
      .catch(err => {
        if(err){
          const {data} = err.response;
          const {errors} = data;
          let catError ="";
          let descError ="";
          let dateError ="";
          if(errors){
            for (let index = 0; index < errors.length; index++) {
              if(errors[index].toLowerCase().includes('category')){
                catError ='select event Category';
              }
              if(errors[index].toLowerCase().includes('description')){
                descError ='event description too short';
              }
              if(errors[index].toLowerCase().includes('date')){
                dateError ='wrong Date.';
              }
            }
          }
          catError !== "" || descError !== ""?
          setMsg(`Error: Event Category not selected and Description too short.`) :
          dateError !==""? setMsg("Error: Invalid date selection."):
          setMsg('Error while publishing event. Try again later.');
        }
        
        console.log();
        setTimeout(() => {
          
          setLoading(false);
        }, 1000);
      });
  }
  
  useEffect(()=>{
  },[]);

  return(
    <Wrap>
      <div className="div-wrap">
        <div className="header">
          <FaArrowLeft className="icon"/>
          <p>Save</p>
        </div>
        <div className="banner">
          
          <div>
            <div className="img-icon" onClick={() => addBgImg()}>
              <FaImage className="icon"/>
            </div>
            <p>Add your Image</p>
            <input type="file" hidden  id="event-banner" onChange={(e)=> handleImgChange(e)}/>
          </div>

        </div>
        {backgroundImgUrl &&<img src={backgroundImgUrl} alt=''/>}
        <div className="form-div">
          
          <form onSubmit={handleSubmit(publishEvent)} >
            <div className="form-1">
              <div>
                <label>Event Title</label><br/>
                <input type="text" placeholder="Event Title" name="title"
                  maxLength={75} 
                  {...register('title',{required:true})}
                />
                <p>0/75</p>
                {errors.title && <p className="error">Event Title is required</p>}
              </div>
              <div>
                <label>Host/Organizer</label><br/>
                <input type="text" placeholder="Name of Host/Organizer" name="host"
                  {...register('host',{required:true})}
                />
                {errors.host && <p className="error">Host Name is required</p>}
              </div>
              <div>
                <label>Event Description</label><br/>
                <input type="text" placeholder="let your participants know why they should attend" name="description"
                  maxLength={150}
                  {...register('description',{required:true})}
                />
                <p>0/150</p>
                {errors.description && <p className="error">Event Description is required</p>}
              </div>
              <div>
                <label>Category</label><br/>
                <select name="_id" {...register('_id')}>
                  {
                    categories.map(category =>{
                      return(
                        <option key={category._id} value={category._id} >
                          {category.name}
                        </option>
                      )
                    })
                  }
                  
                </select>
              </div>
            </div>
            <h5>Event Schedule</h5>
            <p>Schedule date and time of your event and make changes your dashboard.</p>
            <div className="schedule-data">
              <div>
                <label>Event Date</label>
                <input type="date" id="calendar"  name="date"  
                  {...register('date',{required:true})}
                />
                {errors.date && <p className="error">Event Date is Required.</p>}
              </div>
              <div>
                <label>Registration start time(If applicable)</label>
                <input type="time" className="time" name="startTime"
                  {...register('startTime',{required:true})}
                />
                {errors.startTime && <p className="error">Registration Start Time is required</p>}
              </div>
              <div>
                <label>Registration end Date(If applicable)</label>
                <input type="date" className="time" name="endTime"
                  {...register('endTime',{required:true})}
                />
                {errors.endTime && <p className="error">Registration End Time is required</p>}
              </div>
              <div>
                <label>Session start</label>
                <input type="time" className="time" name="sessionStart"
                  {...register('sessionStart',{required:true})}
                />
                {errors.sessionStart && <p className="error">Session Start Time is required</p>}
              </div>
              <div>
                <label>Session ends</label>
                <input type="time" className="time" name="sessionEnd"
                  {...register('sessionEnd',{required:true})}
                />
                {errors.sessionEnd && <p className="error">Session End Time is required</p>}
              </div>
            </div>
            <button className="more" disabled>
              <FaPlus className='icon'/>
              Add more Date
            </button>
            <h5>Location</h5>
            <p>Help people to discover where to show up.</p>
            <div className="location">
              <button className={venue ==="physical"? "selected-location venue":"venue"} onClick={() =>handleVenue("physical")} >Venue</button>
              <button className={venue ==="online"? "selected-location online":"online"}  onClick={() =>handleVenue("online")}>Online</button>
              <div className={venue === "physical"? "" :"hide"}>
                <label>Event Location</label><br/>
                <input type="text" name="location"
                  {...register('location',{required:false})}
                /> <br/>
                <button className="more" disabled>
                  <FaPlus className='icon'/>
                  Add more Location
                </button>
              </div>
              <div className={venue ==="online"?"online-event":"hide"}>
                <p>Set your event room and generate an ID for participants to join.</p>
                <div >
                  <h6>Use Personal event ID</h6>
                  <div className="online-parameter">
                    <p>Generate another event ID for this event </p>
                    <p>Change</p>
                  </div>
                </div>
                <div >
                  <h6>Audio Background </h6>
                  <div className="online-parameter">
                    <p>Add style to your audio background</p>
                    <p><FaCaretRight className="icon"/></p>
                  </div>
                </div>
                <div >
                  <h6>Make Event Public</h6>
                  <div className="online-parameter">
                    <p>Allow anybody without the link or password join your event. </p>
                    <Toggler id="join-by-pwd"/>
                  </div>
                </div>
                <div >
                  <h5>Event Options</h5>
                  <div className="online-parameter">
                    <p>Allow Participant join before host</p>
                    <Toggler id="join"/>
                  </div>
                </div>
                <div >
                  <div className="online-parameter">
                    <p>Automatically record event</p>
                    <Toggler id="record-event"/>
                  </div>
                </div>
                <div className="online-parameter">
                  <div>
                    <p>Recording Location</p>
                  </div>
                  <p>Desktop device</p>
                </div>
                <div >
                  <h5>Settings</h5>
                  <p>Require event passcode</p>
                  <div className="online-parameter">
                    <p>Only users with the event passcode can join the event. </p>
                    <Toggler id='setting'/>
                  </div>
                </div>
                <div>
                  <div className="online-parameter">
                    <p>Passcode</p>
                    <p>W665221</p>
                  </div>
                </div>
                <div >
                  <h6>Only allow authenticated users</h6>
                  <div className="online-parameter">
                    <p>Users not registered on the app can not join event </p>
                    <Toggler id="auth"/>
                  </div>
                </div>
                <div>
                  <div  className="online-parameter">
                    <p>Add to calendar </p>
                    <Toggler id="add-calendar"/>
                  </div>
                </div>
              </div>
            </div>

            <h5>Event fees</h5>
            <p>Help participants know if your event is free or paid</p>
            <div>
              <div className="fees">
                <div>
                  <select name='currency' {...register('currency')}>
                    <option value="NGN">NGN</option>
                    <option vlaue="USD">USD</option>
                  </select>
                </div>
                <div>
                  <input type='text' placeholder="Type amount" name="amount"
                    {...register('amount',{required:true})}
                  />
                  {errors.amount && <p className="error">Fee is required</p>}
                </div>
                <div>
                  <input type='text' id="desc" placeholder="Add more description of payment" name="payment-desc"
                    {...register('payment-desc',{required:false})}
                  />
                </div>
                
              </div>
              <div>
                <input className="link-input" type='text' placeholder="Add link for participant payment" name="pay-link"
                  {...register('pay-link',{required:false})}
                />
                <FaInfoCircle className="icon-warning"/>
              </div>
              <button className="more" disabled>
                <FaPlus className='icon'/>
                Add more Fees
              </button>
            </div>
            <h5>Registration Limit</h5>
            <p>Participants are unable to register once the number registered reaches the limit you set.</p>
            <div className="limit-div">
              <div>
              {errors.participantLimit && <p className="error">Registration Limit is required</p>}
                <input type="text" placeholder="100" name="participantLimit"
                  {...register('participantLimit',{required:true})}
                />
                <FaQuestionCircle className="icon-warning" />
              </div>
              <div className="calendar-setting">
                <p>Add this event to your calendar</p>
                <Toggler id="personal"/>
              </div>
              <p className={msg.toLocaleLowerCase().includes('error')?"error":"text-success"}>{msg}</p>
              {loading?
                <Loader type="ThreeDots" color="#0077b6"/> :
                <button className="send" >Publish</button>
              }
              <div className={venue ==="publish"?"publish-notice":"hide"}>
                <Overlay title="Publish Event" dismiss={handleVenue}/>
              </div>
            </div>
          </form>
          
        </div>
      </div>
      
    </Wrap>
  )
}
export default CreateEvent;

const Wrap = styled.div `
  *{
    margin:0;
    padding:0;
    box-sizing:border-box;
  }
  .pointer{
    cursor:pointer;
  }
  .div-wrap{
    margin:12px 10% 30px 10%;
    box-shadow: 0px 4px 4px 0px #00000040;
    @media screen and (max-width:450px){
      margin:5px auto 30px auto;
    }
  }
  .hide{
    display:none;
  }
  .header{
    display:flex;
    justify-content:space-between;
    font-size:24px;
    padding: 5px 25px;
    align-items:center;
    @media screen and (max-width:450px){
      font-size:18px;
    }

    p{
      color:#0077b6;
      font-weight:600;
    }
    .icon,p{
      cursor:pointer;
    }
  }
  .banner{
    background: #3E4347BD;
    padding:7% 2%; 
    text-align:center;
    

    div{
      color:#fff;
      .img-icon{
        border-radius:50%;
        margin:0 auto;
        background:#fff;
        color:#0077b6;
        padding:9px;
        height: max-content;
        width:max-content;
        .icon{
          font-size: 25px;
          cursor:pointer;
        }
      }
    }
  }
  .form-div{
    padding:12px 50px;
    @media screen and (max-width:450px){
      padding:12px 15px;
    }
    p.error{
      color:red;
      width:100% !important;
    }
    button{
      border:none;
      background:#0077b6;
      color:#fff;
      border-radius:5px;
      padding:5px 15px;
      margin:0 5px 0 0;
    }

    .form-1{
      display:grid;
      grid-template-columns: 1fr 1fr;
      column-gap:5px;
      @media screen and (max-width:450px){
        grid-template-columns: 1fr;
      }
     
      div{
        margin-top:3%;
        input, select{
          border-radius:5px;
          width:97%;
          padding:7px;
          border: 0.4px solid #000;
        }
        input:focus, select:focus{
          outline:none;
          border:0.4px solid #00B4D8;
        }
        p{
          width:10%;
          margin-left:auto;
          margin-right:6%;
        }
      }
     
    }
    h5{
      margin:25px 0 5px 0;
      @media screen and (max-width:450px){
        margin:20px auto 10px auto;
      }
    }
    .schedule-data{
      display:grid;
      grid-template-columns: repeat(auto-fit, minmax(300px,1fr));
      gap:5px;

      @media screen and (max-width:768px){
        grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
      }
      div{
        position:relative;
        margin:15px 0 9px 0;
        @media screen and (max-width:450px){
          margin:0;
        }
      }
      input[type="date"], input[type="time"]{
        border-radius:5px;
        width:97%;
        padding:7px;
        border: 0.5px solid #3E4347AD;
        position:relative;
        @media screen and (max-width:450px){
          width:100% !important;
        }
      }
      input[type="date"]::-webkit-calendar-picker-indicator,input[type="time"]::-webkit-calendar-picker-indicator {
        color: transparent;
        background: url(${dropdown});
        background-repeat:no-repeat;
        background-position:center;
        z-index: 1;
      }
      input[type="date"]:focus,input[type="time"]:focus{
        outline:none;
        border-color:#0077b6;
      }
    }
    .more{
      width:200px;
      height:45px;
      margin:25px 0;
      .icon{
        margin-right:10px;
      }
      @media screen and (max-width:450px){
        margin:20px auto 10px auto;
      }
    }
    .selected-location{
      border: 1px solid #1565C04A;
      background: #CAF0F885 !important;
    }
    .location{
      .venue{
        background:#fff;
        border: 1px solid #C4C4C4;
        margin: 30px 45px 30px 0 ;
        color:#000;
        font-weight:500;
        @media screen and (max-width:450px){
          margin:20px 15px 10px auto;
          width:100px
        }
        
      }
      .online{
        border: 1px solid #C4C4C4;
        background: #fff;
        margin: 30px 0 30px 0 ;
        color:#000;
        font-weight:500;
        @media screen and (max-width:450px){
          margin:20px auto 10px auto;
          width:100px
        }
      }
      input{
        border: 0.5px solid #3E4347AD;
        border-radius:5px;
        padding:7px;
        width:45%;
        @media screen and (max-width:450px){
          width:250px
        }
      }
      input:focus{
        outline:none;
        border:0.4px solid #00B4D8;
      }

      .online-event{

        .online-parameter{
          display: flex;
          align-items:center;
          justify-content:space-between;
          margin:15px 0;
          padding-right:10%;
          @media screen and (max-width:450px){
            width:100%;
            padding-right:2%;
          }
          .icon{
            font-size:24px;
            color:#0077b6;
            cursor:pointer;
          }
        }
      }
    }
    .fees{
      display:flex;
      gap:10px;
      margin:14px 0;
      @media screen and (max-width:768px){
        display:grid;
        grid-template-columns: 1fr 1fr 2.5fr;
        gap:10px;
      }
      @media screen and (max-width:450px){
        gap:5px;
        grid-template-columns: 1fr;
      }
      input{
        border: 0.5px solid #3E4347AD;
        width:350px;
        padding:7px;
        border-radius:5px;
        margin:10px 0;
        @media screen and (max-width:768px){
          width:100%;
        }
        @media screen and (max-width:450px){
          width:90%;
        }
      }
      select{
        width:200px;
        padding:7px;
        margin:10px 0 2px 0;
        border: 0.5px solid #3E4347AD;
        @media screen and (max-width:768px){
          width:100%;
        }
        @media screen and (max-width:450px){
          width:80%;
          font-size:16px;
        }
      }
      input:focus, select:focus{
        outline:none;
        border:0.4px solid #00B4D8;
      }
    }
    .icon-warning{
      color: #FFC431;
    }
    .link-input{
      width: 480px;
      padding:7px;
      border: 0.5px solid #3E4347AD;
      border-radius:5px;
      @media screen and (max-width:650px){
        width:270px;
      }
    }
    .link-input:focus{
      outline:none;
      border:0.4px solid #00B4D8;
    }
    
    .limit-div{
      position:relative;
      input{
        border-radius:5px;
        width:100px;
        padding:7px;
        border: 0.5px solid #3334;
        margin:10px 0;
      }
      input:focus{
        outline:none;
        border:0.4px solid #00B4D8;
      }
      .calendar-setting{
        display:flex;
        gap:25%;
      }
      .send{
        width:270px;
        height:54px;
        border-radius:11px;
        font-weight:600;
        line-height:24px;
        font-size:24px;
        margin:30px 0;
      }
      .publish-notice{
        position:absolute;
        bottom:10px;
        background:#eee;
      }
    }
   
  }
`;