import React,{useState} from "react";
import styled from "styled-components";
import { FaCloudUploadAlt, FaRegFileAlt,FaPen} from "react-icons/fa";
import bordImg from "./board.png";
import Images from "../../../util/img/StreamLayout";


const Board = () => {

  const [resource, setResource] = useState("");

  const showResource = (option) =>{
    setResource(option)
  }
  const handleFile = () =>{
    document.getElementById("file").click();
  }
  return(
    <Wrap>
      <div className="board-container">
        <div className="upload-section">
          <p className={resource ===""?"title":"hide"}>Find your saved boards and uploads here!!</p>
          <img className={resource ===""?"adImg":"hide"} src={bordImg} alt="" />
          <div className={resource !== ""?"saved-resource": "hide"}>
            <div>
              <img src={Images.excel}  alt="" />
              <img src={Images.word}  alt="" />
              <img src={Images.powerpoint}  alt="" />
              <img src={Images.powerpoint1}  alt="" />
            </div>
          </div>
          <button>Create Board</button>
          <div className="flex">
            <p onClick={()=>showResource("template")}>Use Template</p>
            <p onClick={()=>showResource("template")}>Resources</p>
          </div>
          <div className="flex">
            <div>
              <FaRegFileAlt className="icon"/>
              <p onClick={()=>showResource("note")}>Add Note</p>
            </div>
            <div>
              <FaCloudUploadAlt className="icon"/>
              <p onClick={()=>showResource("upload")}>Upload</p>
            </div>
            
          </div>
        </div>
        <div className="main-section">
          <div className={resource ==="upload"?"upload-div":"hide"}>
            <h6>Upload a file</h6>
            <div>
              <button onClick={() =>handleFile()}>Browse your file</button>
              <input type="file" id="file" hidden/>
              <p>or<br/>
              Drag and drop your files here.
              </p>
            </div>
          </div>
          <div className={resource !=="upload"?"template":"hide"}>
            <div className="board-title">
              <p>Board Title <FaPen className="edit"/></p>
              <button>Save</button>
            </div>
            <div className={resource ==="template"?"template-file":"hide"}>
              <div>
                <div className="file">
                  <p id='addfile'>Add file type here</p>
                  <p>or <br/>drag and drop here from your uploads.</p>
                </div>
                <div className="text">
                  <h5>Add a title</h5>
                  <textarea cols="20" rows="5" placeholder="your notes goes here...." />
                </div>
              </div>
              <div>
                <div className="file">
                  <p id='addfile'>Add file type here</p>
                  <p>or <br/>drag and drop here from your uploads.</p>
                </div>
                <div className="text">
                  <h5>Add a title</h5>
                  <textarea cols="20" rows="5" placeholder="your notes goes here...." />
                </div>
              </div>
              <div>
                <div className="file">
                  <p id='addfile'>Add file type here</p>
                  <p>or <br/>drag and drop here from your uploads.</p>
                </div>
                <div className="text">
                  <h5>Add a title</h5>
                  <textarea cols="20" rows="5" placeholder="your notes goes here...." />
                </div>
              </div>
            </div>
            <div className={resource ==="note"?"note":"hide"}>
              <div className="text">
                <h5>Add a title</h5>
                <textarea cols="20" rows="5" placeholder="your notes goes here...." />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrap>
  )
}
export default Board;

const Wrap = styled.main `
  *{
    margin:0;
    padding:0;
    box-sizing:border-box;
  }
  .hide{
    display:none;
  }
  .board-container{
    display:grid;
    grid-template-columns: 1fr 3fr;
    gap:0;

    @media screen and (max-width:450px){
      grid-template-columns:1fr;
    }

    .upload-section{
      padding:40px 27px 0 27px;
      border-right: 1px solid #00000059;
      text-align:center;

      @media screen and (max-width:450px){
        padding:10px 17px 0 17px;
        border-right: none;
      }

      .title{
        font-size:24px;
        font-weight:500;
        line-height:27px;

        @media screen and (max-width:768px){
          font-size:18px;
        }
      }
      .adImg{
        width:100%;
        margin:51px auto 54px auto; 
        @media screen and (max-width:450px){
          margin:21px auto 34px auto;
          width:80%;
        }
      }

      .saved-resource{
        div{
          display:grid;
          grid-template-columns:1fr 1fr;
          gap:2px;
          
          width:95%;
          margin:35px auto;
          img{
            width:98%;
            cursor:pointer;
          }
         
        }
        padding:10px;
      }

      button{
        border:none;
        border-radius:5px;
        width:238px;
        height:57px;
        background:#0077b6;
        font-weight:500;
        font-size:28px;
        color:#fff;
        margin-bottom: 60px;
        @media screen and (max-width:450px){
          margin-bottom: 20px;
          width:90%;
        }
      }
      .flex{
        display:flex;
        margin-bottom:22px;
        justify-content:space-between;
        @media screen and (max-width:450px){
          margin: 10px 8%;
        }

        p{
          color:#0077b6;
          font-size:18px;
          font-weight:500;
          cursor:pointer;
        }
        div{
          text-align:center;
          margin-bottom:40px;
          p{
            font-size:16px;
          }
          .icon{
            font-size:37px;
          }
        }
      }
    }

    .main-section{
      background: #F5F5F5;
      text-align:center;
      .upload-div{
        margin:7% auto;
        width:40%;

        @media screen and (max-width:768px){
          margin:10% auto;
          width:90%;
        }

        h6{
          font-size:24px;
        }

        div{
          background:#fff;
          padding:100px;
          width:100%;
          margin-top:10px;
          @media screen and (max-width:450px){
            padding:15%;
          }
          p{
            line-height:21px;
          }
          button{
            border:none;
            background: #CAF0F8;
            width:90%;
            padding:5px;
            border-radius:5px;
            box-shadow: 0px 4px 4px 0px #00000008;

          }
        }
      }

      .template{
        background: #F5F5F5;
        @media screen and (max-width:450px){
          margin:4% auto;
        }

        .board-title{
          display:flex;
          padding:2% 18%;
          justify-content:space-between;
          background:#fff;

          @media screen and (max-width:450px){
            padding:2% 5%;
          }

          .edit{
            cursor:pointer;
            font-size:21px;
          }
          button{
            width:70px;
            height:30px;
            border:none;
            border-radius:5px;
            background:#0077b6;
            color:#fff;
            cursor:pointer;
          }
        }
        .template-file{
          margin:5% 15%;
          border: 1px solid #2632385C;
          @media screen and (max-width:450px){
            margin:0 auto;
          }

          .file{
            background: #E0E0E0;
            text-align:center;
            padding:3% 0;
            #addfile{
              color:#0077b6;
              font-weight:700;
              cursor:pointer;
            }
          }
          .text{
            background:#fff;
            padding:5px 10px;
            textarea{
              width:100%;
              border:none;
            }
            textarea:focus{
              outline:none;
            }
          }
        }
        .note{
          margin:15% 20%;
          .text{
            background:#fff;
            border: 1px solid #2632385C;
            padding:5px 20px;
            text-align:left;
            textarea{
              margin-top:10px;
              width:100%;
              border:none;
            }
            textarea:focus{
              outline:none;
            }
          }
        }
      }
    }
  }
`;
