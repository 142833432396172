import React,{useState} from "react";
import { useHistory } from "react-router";
import styled  from "styled-components";
import LayoutImages from "../../../util/img/StreamLayout";
import { FaCaretDown, FaEdit, FaEye,FaHeart,FaInfoCircle,FaMicrophone,  FaSignOutAlt, FaStar,  FaUsers, FaUsersCog,} from "react-icons/fa";
import {MdFileUpload} from "react-icons/md";
import {RiMessage2Fill} from "react-icons/ri";
import {BsThreeDotsVertical} from "react-icons/bs";
import Follow from "./FollowHost";
import Note from "./TakeNote";
import Review from "./ReviewEvent";
import Report from "./ReportHost";
import Uploads from "./Uploads";


const StreamLayout = (props) =>{

  const {show, item} = props;
  const [active, setActive] = useState('');
  const [selectedOpt, setSelectedOpt] = useState('');
  const history = useHistory();

  const activeClass = (opt) => {
    if(active === opt){
      setActive("");
      return;
    }
    setActive(opt);
  }
  const selected = (item) => {
    if(selectedOpt === item){
      setSelectedOpt("")
      return;
    }
    setSelectedOpt(item);
  }

  //Exit streaming
  const exit =() => {
    history.push("/")
  }

  return(
    <Wrapper>
      <div className="stream-nav">
        <img src={LayoutImages.eroi} alt="logo" />
        <h6 className="logo-text">ElRoi</h6>
      </div>
      <div className="end-div">
        <span className="drop-down">
          <img src={LayoutImages.lock} alt="lock" />
          ElRoi <FaCaretDown />
        </span>
        <button className="btn-end">End</button>
      </div>
      
      <div className="live-show">
        <div className="metrix">
          <p> {props.title}</p>
          <span className="cta-btn">
            <button className="btn-live">Live</button>
            <button className="btn-view"><FaEye /> 3k</button>
          </span>
        </div>
        <div>
          {props.children}
        </div>
      </div>
      {/* menu items */}
      <div className="menu-opt">
        <div onClick={()=>setActive("mute")}
          className={active ==='mute'? "opt text-center active-opt":
          "opt text-center"}
        >
          <FaMicrophone className="icon"/>
          <p>Mute</p>
        </div>


        {/* Upload file menu */}
        <div onClick={()=>{activeClass("upload"); selected("upload")}}
          className={active ==='upload'? "opt text-center active-opt":
          "opt text-center"}
        >
          <MdFileUpload className="icon"/>
          <p>Upload</p>

          <div className={selectedOpt ==="upload" && active==="upload"?"uploader":"uploader hidden"}> {/*menu to handle file upload */}
            <Uploads />
          </div>
        </div>


        <div onClick={()=>activeClass("share")}
          className={active ==='share'? "opt text-center active-opt":
          "opt text-center"}
        >
          <FaHeart className="icon"/>
          <p>Like</p>
        </div>


        <div onClick={()=>{activeClass("users"); show(true);item("participant")}}
          className={active ==='users' && item ==="participant"? "opt text-center active-opt":
          "opt text-center"}
        >
          <FaUsers className="icon"/>
          <p>participants</p>
        </div>


        <div onClick={()=>{activeClass("chat");show(true);item("chat")}}
          className={active ==='chat' && item ==="chat"? "opt text-center active-opt":
          "opt text-center"}
        >
          <RiMessage2Fill className="icon"/>
          <p>Chat Rooms</p>
        </div>


        <div onClick={()=>activeClass("quit")}
          className={active ==='recording'? "opt text-center active-opt":
          "opt text-center"}
        >
          <FaSignOutAlt className="icon leave"/>
          <p className="leave">Leave</p>

          <div className={active !=="quit"?"quit hidden":"quit"}>
            <p>Quit Streaming?</p>
            <div>
              <button className="abort">Cancel</button>
              <button className="ok" onClick={()=>exit()}>Ok</button>
            </div>
          </div>
        </div>


        <div onClick={()=>{activeClass("more")}}
          className={active ==='more'? "opt text-center active-opt":
          "opt text-center"}
        >
          <BsThreeDotsVertical className="icon "/>
          <p>More</p>

          <div className={active !=="more" || selectedOpt !==""?"more-opt hidden":"more-opt"}>
            <p onClick={() =>selected("notes")}><FaEdit className="more-icon"/>Take Note</p>
            <p onClick={() =>selected("review")}><FaStar className="more-icon"/>Review event</p>
            <p onClick={()=>selected("report")}><FaInfoCircle className="more-icon"/>Report host</p>
            <p onClick={()=>selected("follow")}><FaUsersCog className="more-icon"/>Follow Host</p> 
          </div>
          <div className={selectedOpt ==='notes'?"take-note":"hidden"}>
            <Note selected={selected} />
          </div> 
          <div className={selectedOpt ==='review'?"review-host":"hidden"}>
            <Review selected={selected} active={activeClass}/>
          </div>
          <div className={selectedOpt ==='report'?"report-host":"hidden"}>
            <Report selected={selected} active={activeClass}/>
          </div>
          <div className={selectedOpt ==='follow'?"follow":"hidden"}>
            <Follow selected={selected} active={activeClass}/>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
export default StreamLayout;

const Wrapper = styled.div `
  max-height: 100%;
  .hidden{
    display:none;
  }
  .active-opt{
    color:var(--btnColor) !important;
  }


  .stream-nav{
    background:var(--primaryColor);
    padding:5px;
    position:relative;
    img{
      margin:5px 0 0 54px;
    }
    .logo-text{
      color:#fff;
      position:absolute;
      top:28px;
      left:80px;
    }
  }
  .end-div{
    background: #21214B;
    color:#fff;
    box-shadow: 0px 2px 2px 0px #00000040;
    overflow:hidden;

    .btn-end{
      background: #D42323;
      border:none;
      color:#fff;
      height:34px;
      width:143px;
      float:right;
      margin: 20px 24px 23px 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 14px;
      border-radius:11px;
    }
    .drop-down{
      img{
        margin:30px 11px 25px 52px;
      }
    }
    @media screen and (max-width: 600px){
      .btn-end{
        width:80px;
        margin: 20px 24px 23px 0;
        font-size: 18px;
        line-height: 20px;
      }
    }
  }


  .live-show{
    position: relative;
    width:100%;
    overflow:hidden;
    
  }


  .metrix{
    position:absolute;
    box-shadow: 2px 2px 2px 0px #0000000D;
    background: #FFFFFF6E;
    padding: 17px 25px 16px 52px;
    width: 100%;
    display: grid;
    grid-template-columns:3fr 1fr;
    align-items:center;

    .cta-btn{
      display:flex;
      justify-content:flex-end;
      .btn-live, .btn-view{
        width:69px;
        height:34px;
        color:#fff !important;
        border:none;
      }
      .btn-live{
        background:#D42323;
        margin-right:8px;
      }
      .btn-view{
        background:#000;
        color:#fff !important;
        width:69px;
        height:34px;
      }
    }
    @media screen and (max-width:600px){
      padding: 10px 5px 11px 5px;
      p{
        font-size:12px;
        width:max-content;
      }
      .cta-btn{
        .btn-live, .btn-view{
          width:43px;
          height:24px;
          font-size:12px;
        }
      }
    }
  }
  

  .menu-opt{
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding: 15px  10px;

    
    div.opt{
      cursor:pointer;
      position:relative;
      p{
        margin:0;
      }
      .icon{
        font-size: 20px;
      }
      .leave{
        color:red !important;
      }
      @media screen and (max-width:768px){
        p{
          font-size:10px;
          font-weight:600;
        }
      }

      .more-opt{
        background:#ffffffab;
        color:#000 !important;
        border:2px solid #eee;
        padding:15px;
        text-align:left !important;
        cursor:default !important;
        width: 200px;
        position: absolute;
        bottom:70px;
        right:0px;
        p{
          cursor:pointer;
          margin: 10px 0
        }
        .more-icon{
          margin-right:15px;
        }
      }
      
      // upload control start
      .uploader{
        position:absolute;
        left: -10px;
        bottom: 70px;

        @media screen and (max-width:450px){
          left:-40px;
          bottom:60px;
        }
      }
        
      } //uploader ends

      //leave  
      .quit{
        width: max-content;
        border:2px solid #eee;
        background:#ffffffab;
        padding:5px 15px 10px 15px;
        p{
          font-size:18px;
          color:red !important;
          margin-bottom:10px;
        }
        position:absolute;
        bottom: 60px;
        right:6%;
        
        button{
          border:none;
          border-radius:5px;
          color:#fff;
        }
        .abort{
          background:red;
          margin-right:10px;
        }
        .ok{
          background:var(--btnColor);
          width:60px;
        }
        @media screen and (max-width:600px){
          p{
            font-size:12px;
          }
        }
      }
      //Take notes
      .take-note{
        position:absolute;
        right:5px;
        bottom:70px;
        color: #000 !important;
      }
      //Review host
      .review-host{
        position:absolute;
        right:5px;
        bottom:70px;
        color: #000 !important;

        @media screen and (max-width:768px){
          bottom:60px;
          right:3px;
        }
      }
      //report-host
      .report-host{
        position:absolute;
        right:5px;
        bottom:70px;

        @media screen and (max-width:450px){
          bottom:60px;
          right:1px;
        }
      }
      //follow host
      .follow{
        position: absolute;
        right:5px;
        bottom:60px;
        color:#000 !important;
      }
      
    }
    
  }
`;