import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AsideSection from "./AsideSection";
import { FaSearch, FaCaretDown, FaFolder } from "react-icons/fa";


const Dashboard = (props) => {

  const {asidImg,text} = props;
  const [clicked, setClicked] = useState("allevent");
  const [warning, setWarning] = useState(false);
 
  const allEvent = [{
      date:"06/05/2021",
      topic:"Regional Review Meeting",
      action:"Delete",
      ctaBtn:"view",
      name:"allevent"

    },
    {
      date:"06/05/2021",
      topic:"Regional Review Meeting",
      action:"Delete",
      ctaBtn:"view",
      name:"allevent"
  
    },
    {
      date:"06/05/2021",
      topic:"Regional Review Meeting",
      action:"Delete",
      ctaBtn:"view",
      name:"allevent"
  
    },
    {
      date:"06/05/2021",
      topic:"Regional Review Meeting",
      action:"Delete",
      ctaBtn:"view",
      name:"allevent"
  
    }
  ];
  const [data, setData] = useState(allEvent);
  const published = [{
      date:"06/05/2021",
      topic:"Regional Review Meeting",
      action:"Remove",
      ctaBtn:"Analytic",
      name:"published",
      status: "Occured",

    },
    {
      date:"06/05/2021",
      topic:"Regional Review Meeting",
      action:"Remove",
      ctaBtn:"Analytic",
      name:"published",
      status: "Occured",

    },
    {
      date:"06/05/2021",
      topic:"Regional Review Meeting",
      action:"Remove",
      ctaBtn:"Analytic",
      name:"published",
      status: "Pending",

    },
    {
      date:"06/05/2021",
      topic:"Regional Review Meeting",
      action:"Remove",
      ctaBtn:"Analytic",
      name:"published",
      status: "Pending",

    },
    {
      date:"06/05/2021",
      topic:"Regional Review Meeting",
      action:"Remove",
      ctaBtn:"Analytic",
      name:"published",
      status: "Occured",

    },
    {
      date:"06/05/2021",
      topic:"Regional Review Meeting",
      action:"Remove",
      ctaBtn:"Analytic",
      name:"published",
      status: "Pending",

    },
  ];
  const saved = [{
      date:"06/05/2021",
      topic:"Regional Review Meeting",
      action:"Preview",
      ctaBtn:"Edit",
      name:"saved"
    },
    {
      date:"06/05/2021",
      topic:"Regional Review Meeting",
      action:"Preview",
      ctaBtn:"Edit",
      name:"saved"
  
    },
    {
      date:"06/05/2021",
      topic:"Regional Review Meeting",
      action:"Preview",
      ctaBtn:"Edit",
      name:"saved"
  
    },
    {
      date:"06/05/2021",
      topic:"Regional Review Meeting",
      action:"Preview",
      ctaBtn:"Edit",
      name:"saved"
  
    },
  ];
  const board = [{
      date:"06/05/2021",
      topic:"Regional Review Meeting",
      action:"Delete",
      ctaBtn:"Edit",
      name:"board"
    },
    {
      date:"06/05/2021",
      topic:"Regional Review Meeting",
      action:"Delete",
      ctaBtn:"Edit",
      name:"board"
    },
    {
      date:"06/05/2021",
      topic:"Regional Review Meeting",
      action:"Delete",
      ctaBtn:"Edit",
      name:"board"
    },
    {
      date:"06/05/2021",
      topic:"Regional Review Meeting",
      action:"Delete",
      ctaBtn:"Edit",
      name:"board"
    },
    {
      date:"06/05/2021",
      topic:"Regional Review Meeting",
      action:"Delete",
      ctaBtn:"Edit",
      name:"board"
    },
  ];
  const handleAllEvent = (option) =>{
    setClicked(option);
    option ==="board"?
    setData(board)
    :option ==="published"?
    setData(published)
    :option === "saved"?
    setData(saved)
    :
    setData(allEvent)
  }
  const handleWarning = (item) =>{
    setWarning(item);
  }

  useEffect(() =>{
    
  })

  return(
    <Wrap>
      <div className="canvas">
        <div className="aside-content">
          <AsideSection image={asidImg} text={text} />
        </div>
        <div className="main-content">
          <div className="search-div">
            <div>
              <FaSearch />
              <input type="text" placeholder="search" />
            </div>
            <p>Filter</p>
            <div>
              <input type="text" placeholder="last 30 days" />
              <FaCaretDown />
            </div>
          </div>
          <div className="activity-div">
            <div className="event-stat">
              <div className="all">
                <h4>50</h4>
                <p>All Events</p>
              </div>
              <div className="publish">
                <h4>25</h4>
                <p>Published</p>
              </div>
              <div className="saved">
                <h4>5</h4>
                <p>Saved</p>
              </div>
              <div className="board">
                <h4>20</h4>
                <p>Board</p>
              </div>
            </div>
            <div className="pending-events">
              <h4>Pending Events</h4>
              <div className="pending-item">
                <p>8:00<br/>AM</p>
                <span>
                  <h6>Morning Prayer</h6>
                  <p>Meeting ID: 5677 78899 78</p>
                </span>
                <div><button>Start</button></div>
              </div>
              <div className="pending-item">
                <p>8:00<br/>AM</p>
                <span>
                  <h6>Wednessday Cell <br/>leaders traingin</h6>
                  <p>Meeting ID: 5677 78899 78</p>
                </span>
                <div><button>Start</button></div>
              </div>
            </div>
          </div>
          <div className="list">
            <div className="header-div">
              <p onClick={()=>handleAllEvent("allevent")} className={clicked ==='allevent'?'clicked':null}>
                All Events</p>
              <p onClick={()=>handleAllEvent("published")} className={clicked ==='published'?'clicked':null}>Published</p>
              <p onClick={()=>handleAllEvent("saved")} className={clicked ==='saved'?'clicked':null}>Saved</p>
              <p onClick={()=>handleAllEvent("board")} className={clicked ==='board'?'clicked':null}>Board</p>
            </div>
            <div>
              {
                data.map((item,index) => {
                  return(
                    <div key={index} className="data">
                      <p>{item.date}</p>
                      <span>
                        <p>{item.topic}</p>
                        {
                          item.name ==="published"?
                          <p className={item.status.toLowerCase() ==="pending"?"blue":"red"}>{item.status}</p> : 
                          item.name ==="board"?
                          <p className="icon"><FaFolder /></p> : null
                        }
                      </span>
                      {
                        item.action.toLowerCase() ==="remove"||item.action.toLowerCase()==="delete"?
                        <p className="action" onClick={() =>handleWarning(true)}>{item.action}</p> :
                        <p className="action">{item.action}</p>
                      }
                     
                      <button>{item.ctaBtn}</button>
                    </div>
                  )
                })
              }
            </div>
            <div className={warning ===false?'hide':'delete-div'}>
              <h5>Are you sure?</h5>
              <p className="children">
                If you remove this event, your event will no longer be seen online.
              </p>
              <div>
                <button onClick={() =>handleWarning(false)}>Cancel</button>
                <button className='actBtn' onClick={() =>handleWarning(false)}>Remove</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrap>
  )
}
export default Dashboard;

const Wrap = styled.main `
  *{
    margin:0;
    padding:0;
    border-sizing:border-box;
    font-family:Roboto;
  }
  .canvas{
    display:grid;
    grid-template-columns:18% 82%;
    position:relative;
    background:#03045E;
    @media screen and (max-width:768px){
      display:grid;
      grid-template-columns:100%;
      grid-template-areas:
      "image"
      "items";
      background: #fff;
    }
  
    
    .aside-content{
  
      @media screen and (max-width:768px){
        grid-area:image;
      }
    }
    .main-content{
      border-top-left-radius:15px ;
      border-top-right-radius:5px ;
      border-bottom-left-radius:15px ;
      border-bottom-right-radius:0 ;
      margin-top:10px;
      background:#fff;
  
      @media screen and (max-width:768px){
        grid-area:items;
      }
      .search-div{
        display:flex;
        margin:28px 0 0 22px;
        width:max-content;
        gap:15px;
        place-items:center;
        @media screen and (max-width:450px){
          flex-direction:column;
          margin:18px auto 15px auto;
          
        }
    
        div{
          border: 1px solid #FFFFFF33;
          box-shadow: 6px 6px 16px 4px #D1CDC780;
          padding:5px;
          border-radius:5px;
          width:max-content;
        }
        input{
          border:none;
          width:195px;
          transition: all 300ms ease-out;
          @media screen and (max-width:450px){
            width:140px;
          }
        }
        input:focus{
          outline:none;
          width:210px;
          @media screen and (max-width:450px){
            width:240px;
          }
        }
      }
      .activity-div{
        display:grid;
        grid-template-columns: 1fr 1fr;
        gap:10px;
        place-items:center;
  
        @media screen and (max-width:768px){
          grid-template-columns:1fr;
        }
        @media screen and (max-width:450px){
          grid-template-columns:100%;
        }

        .event-stat{
          display: flex;
          gap:17px;
          place-items:center;
          margin:0 10px 0 25px;
          @media screen and (max-width:450px){
            display:grid;
            grid-template-columns:1fr 1fr;
          }
          @media screen and (max-width:768px){
            margin:35px auto;
            width:91%;
          }
          .all, .publish, .saved, .board{
            padding: 22px;
            color:#fff;
            box-shadow: 6px 6px 16px 4px #D1CDC780;
            height:151px;
            width:128px;
            border-radius:8px;
            text-align:center;
            h4{
              margin:26px 0 5px 0;
            }
            @media screen and (max-width:450px){
              width:100%;
              margin: 4px auto;
            }
          }
          .all{
            background: linear-gradient(220.2deg, #455A64 -17.73%, #3C2F46 3%, #2E5468 96.99%, #0B1034 99.77%);
          }
          .publish{
            background: linear-gradient(220.59deg, #3C58A0 -31.05%, #03045E 32.1%, #3C58A0 103.06%);

          }
          .saved{
            background: linear-gradient(213.61deg, #1B2C30 0.39%, #0E81C8 2.65%, #1C4B55 88.9%, #283746 96.69%);

          }
          .board{
            background: linear-gradient(221.98deg, #CAF0F8 -1.1%, #68CCE0 -1.1%, #7DAFFF 53.92%, rgba(0, 119, 182, 0.71) 95.64%);

          }
        }

        .pending-events{
          border: 1px solid #FFFFFF33;
          box-shadow: 6px 6px 16px 4px #D1CDC780;
          margin:0 18px 18px 10px;
          width:90%;
          border-radius: 11px;
          padding:13px 18px;

          .pending-item{
            display:flex;
            gap:10px;
            justify-content:space-between;
            padding:10px;
            margin:10px 0 5px 0;
            
            span{
              p{
                font-size:12px;
              }
            }
           

            button{
              width:100px ! important;
              background: #B0DAF1;
              padding:5px 15px;
              border-radius:5px;
              border:none;

            }
          }
        }
      }
  
      .list{
        border: 1px solid #FFFFFF33;
        box-shadow: 6px 6px 16px 4px #D1CDC780;
        margin:18px 16px 30px 24px;
        padding:21px 35px;
        position:relative;

        @media screen and (max-width:450px){
          margin:18px 3px 20px 3px;
          padding:15px 5px;
        }

        .header-div{
          display:flex;
          justify-content:space-between;
          border-bottom:1px solid #ccc;
          margin:0 0 25px 0;
          
          p{
            font-size:24px;
            font-weight:700px;
            cursor:pointer;

            @media screen and (max-width:450px){
              font-size:14px;
            }
          }
          .clicked{
            color: #0077B6;
            border-bottom:2px solid #0077b6;
          }
          p:hover{
            color: #0077B6;
          }
        }
        .data{
          display:flex;
          justify-content:space-between;
          margin:4px 10px;

          @media screen and (max-width:450px){
            margin:4px 0;
          }

          button{
            border:none;
            background:#0077b6;
            width:100px;
            color:#fff;
            border-radius:5px;
            height:35px;
            font-size: 21px;

            @media screen and (max-width:450px){
              width:60px;
              height:25px;
              font-size: 12px;
            }
          }
          p{
            font-size:18px;
            font-weight:400;

            @media screen and (max-width:450px){
              font-size:12px;
            }
          }
          p.action{
            color:red;
            cursor:pointer;
          }
          .red{
            color:red;
            text-align:center;
          }
          .blue{
            color:#0077b6;
            text-align:center;
          }
          .icon{
            color:#0077b6;
            text-align:center;
          }
        }

        .delete-div{
          position:absolute;
          right:4px;
          bottom:10px;
          background:#eee;
          border:1px solid #eee;
          width:250px;
          border-radius:5px;
          padding:20px 15px 10px 15px;
          h5{
            font-size:18px;
            font-weight:600;
            margin:0 0 10px 0;
          }
          div.children{
            margin:5px 0 10px 0;
          }
          button{
            border:none;
            font-weight:bold;
            transition:all 2s ease;
            padding:2px 5px;
            margin-top:10px;
          }
          .actBtn{
            color:red;
            margin:0 0 0 30%;
          }
          button:hover{
            background:#0077b6;
            color:#fff;
            border-radius:5px;
          }
          .actBtn:hover{
            background:red;
            color:#fff;
            border-radius:5px;
          }
        }
        .hide{
          display:none;
        }
      }
      
    }
   
  }
`;