import React,{useState} from "react";
import styled from "styled-components";
import Images from "../../util/img/regImages";
import Loader from "react-loader-spinner";
import {useForm}  from "react-hook-form";
import Axios from "axios";
const SignUp = () => {
  
  const [loading,setLoading] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [accept, setAccept] = useState(false);
  const {register, handleSubmit,formState:{errors},} = useForm();

  const onHandleSubmit= (data) =>{
    
    if(!errors.lenght){
      setErrorMsg("");
      setLoading(true);
      if(data.password === data.confirmPassword ){
        if(accept !== true){
          setErrorMsg("You must accept our Terms and Conditions to proceed.");
          setLoading(false);
          return;
        }
        let userDetails ={
          "firstName": data.firstname,
          "lastName": data.lastname,
          "email": data.email,
          "password": data.password
        }
        setTimeout(() => {
          setLoading(false);
          console.log(userDetails);
          return Axios.post("https://elroi.live/api/v1/auth/signup",userDetails)
            .then(async (res) =>{
              console.log(res)
              const {data, status} =  await res.data;
              console.log(data)
              if(status ==="success"){
                sessionStorage.setItem("userToken", data.token);
                sessionStorage.setItem("user", `${data.user.firstName} ${data.user.lastName}`)
                window.location.href="/verify-account";
              }
            }).catch(err => {
              console.log(err);
              setErrorMsg("Unable to Create Account. Try again later.")
              });
          
        }, 2000);
      }else{
        setTimeout(() => {
          setLoading(false);
          setErrorMsg("Password do not match.");
        }, 2000);
      }
      
    }
  }
  return (
    <SignUpWrapper className="container-fluid">
      <div className="row">
        <div className="text-center img-div col-md-7 col-sm-12">
          <img className="img-fluid" src={Images.reghero} alt="HeroImage" />
        </div>
        <div className="form-div col-md-5 col-sm-12">
          <h4>Let's get you started!</h4>
          <p>Already have an account? <a href="/login">Login here.</a></p>
          <form onSubmit={handleSubmit(onHandleSubmit)}>
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input className="form-control" type="text" name="firstName" id="firstName" 
                {...register('firstname',{required:true})}
              />
              {errors.firstname && <p className="error">First Name is required</p>}
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input className="form-control" type="text" name="lastName" id="lastName" 
                {...register('lastname',{required:true})}
              />
              {errors.lastname && <p className="error">Last Name is required</p>}
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input className="form-control" type="email" name="email" id="email" 
                {...register('email',{required:true})}
              />
              {errors.email && <p className="error">Email is required</p>}
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input className="form-control" type="text"  name="phone" id="phone"
                {...register('phone',{required:true, minLength:11})}
              />
              {errors.phone && <p className="error">Invalid Phone Number</p>}
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input className="form-control" type="password" name="password" id="password" 
                {...register('password',{required:true, minLength:8})}
              />
              {errors.password && <p className="error">Password is required</p>}
            </div>
            <div className="form-group">
              <label htmlFor="confirm">Confirm Password</label>
              <input className="form-control" type="password" name="confirm" id="confirmPassword" 
                {...register('confirmPassword',{required:true})}
              />
              {errors.confirmPassword && <p className="error">Confirm Password is required</p>}
            </div>
            <div className="form-group my-1">
            <label><input type="checkbox" /> Remember me.</label> <br/>
              <label><input type="checkbox" onClick={()=>{setAccept(!accept)}} /> I agree to the <span className="terms">Terms and Privacy Policy</span></label>
            </div>
            <p className="error">{errorMsg}</p>
            <div className="form-group">
              { loading !==true ? <button type="submit" className="btn form-btn my-2" onClick={()=>handleSubmit()}>SIGN UP</button>:
                <Loader type="ThreeDots"  />
              }
             
            </div>
            <div className="form-group social-media">
              <p className="lead">Or Signup using</p>
              <img src={Images.facebook}  alt="facebook" />
              <img src={Images.google} className="mx-4" alt="google"/>
            </div>
          </form>
        </div>
      </div>
    </SignUpWrapper>
  )
}

export default SignUp;

const SignUpWrapper = styled.div `

  .img-div{
    img{
      margin:17% 5px 0 0;
    }
  }
  .error{
    color:red;
    margin:3px;
  }
  .form-div{
    background:var(--bgColor);
    padding: 5% 7% 7% 2%;

    a{
      text-decoration:none;
    }
    form{
      width:90%;
    }
    .form-btn{
      background:var(--btnColor);
      width:100%;
      color:#fff;
    }
  }
  .social-media img{
    cursor:pointer;
  }
  @media screen and (max-width: 980px){
    
  }
`;