import upImages from "./img/upcomingEvent";
import hostIcons from "./img/hosts";
import attendedImage from "./img/attendedEvent";
import registereImages from "./img/registeredEvent";
import liveImages from "./img/LiveEvent";
import prefImages from "../../util/img/prefrence/index";
import review from "./img/reviewer";


const upComingEvents =[
  {
    id:1,
    banner:upImages.event1 ,
    host:{
      icon: hostIcons.host1,
      name:"The Beliver's tabernacle",
      date:"16/04/2021",
      venue:"Badagry"
    },
    action:"register"
  },
  {
    id:2,
    banner: upImages.event2,
    host:{
      icon: hostIcons.host1,
      name:"The Beliver's tabernacle",
      date:"16/04/2021",
      venue:"Badagry"
    },
    action:"register"
  },
  {
    id:3,
    banner: upImages.event3,
    host:{
      icon: hostIcons.host1,
      name:"The Beliver's tabernacle",
      date:"16/04/2021",
      venue:"Badagry"
    },
    action:"register"
  },
  {
    id:4,
    banner:upImages.event1 ,
    host:{
      icon: hostIcons.host1,
      name:"The Beliver's tabernacle",
      date:"16/04/2021",
      venue:"Badagry"
    },
    action:"register"
  },
  {
    id:5,
    banner: upImages.event2,
    host:{
      icon: hostIcons.host1,
      name:"The Beliver's tabernacle",
      date:"16/04/2021",
      venue:"Badagry"
    },
    action:"register"
  },
  {
    id:6,
    banner: upImages.event3,
    host:{
      icon: hostIcons.host1,
      name:"The Beliver's tabernacle",
      date:"16/04/2021",
      venue:"Badagry"
    },
    action:"register"
  }
]

const hosts = [
  {
    id:1,
    name: "The Believers Tabernacle",
    has_event:true,
    banner: hostIcons.host1
  },
  {
    id:2,
    name: "The Believers Tabernacle",
    has_event:false,
    banner: hostIcons.host2
  },
  {
    id:3,
    name: "The Believers Tabernacle",
    has_event:true,
    banner: hostIcons.host3
  },
  {
    id:4,
    name: "The Believers Tabernacle",
    has_event:false,
    banner: hostIcons.host4
  },
]

const attended = [
  {
    id:1,
    banner:attendedImage.event1 ,
    host:{
      icon: hostIcons.host1,
      name:"The Beliver's tabernacle",
      date:"16/04/2021",
      venue:"Badagry"
    },
    action:"view"
  },
  {
    id:2,
    banner:attendedImage.event2 ,
    host:{
      icon: hostIcons.host1,
      name:"The Beliver's tabernacle",
      date:"16/04/2021",
      venue:"Badagry"
    },
    action:"view"
  },
  {
    id:3,
    banner: attendedImage.event3 ,
    host:{
      icon: hostIcons.host1,
      name:"The Beliver's tabernacle",
      date:"16/04/2021",
      venue:"Badagry"
    },
    action:"view"
  },
]

const registered = [
  {
    id:1,
    banner:registereImages.event1 ,
    host:{
      icon: hostIcons.host1,
      name:"The Beliver's tabernacle",
      date:"16/04/2021",
      venue:"Badagry"
    },
    action:"view"
  },
  {
    id:2,
    banner:registereImages.event2 ,
    host:{
      icon: hostIcons.host1,
      name:"The Beliver's tabernacle",
      date:"16/04/2021",
      venue:"Badagry"
    },
    action:"view"
  },
  {
    id:3,
    banner: registereImages.event3 ,
    host:{
      icon: hostIcons.host1,
      name:"The Beliver's tabernacle",
      date:"16/04/2021",
      venue:"Badagry"
    },
    action:"view"
  },
]

const liveEvent = [
  {
    id:1,
    banner:liveImages.event1 ,
    host:{
      icon: hostIcons.host1,
      name:"The Beliver's tabernacle",
      date:"16/04/2021",
      venue:"Badagry"
    },
    action:"join"
  },
  {
    id:2,
    banner:liveImages.event2 ,
    host:{
      icon: hostIcons.host1,
      name:"The Beliver's tabernacle",
      date:"16/04/2021",
      venue:"Badagry"
    },
    action:"join"
  },
  {
    id:3,
    banner: liveImages.event3 ,
    host:{
      icon: hostIcons.host1,
      name:"The Beliver's tabernacle",
      date:"16/04/2021",
      venue:"Badagry"
    },
    action:"join"
  },
]
const profilePendingEvents = [
  {
    id:1,
    time:"8:00 AM",
    topic:"Morning Prayer",
    meetingId:"5677 78899 78",
    action:"Start"
  },
  {
    id:2,
    time:"8:00 AM",
    topic:"Morning Prayer",
    meetingId:"5677 78899 78",
    action:"Join"
  },
  {
    id:3,
    time:"8:00 AM",
    topic:"Morning Prayer",
    meetingId:"5677 78899 78",
    action:"Start"
  }
]
const myInterest = [
  {
    id:1,
    icon: prefImages.church,
    name: "religion"
  },
  {
    id:2,
    icon: prefImages.edu,
    name: "school"
  },
  {
    id:3,
    icon: prefImages.media,
    name: "Media"
  },
  {
    id:4,
    icon: prefImages.business,
    name: "Business"
  },
  {
    id:5,
    icon: prefImages.tech,
    name: "Technology"
  },
  {
    id:6,
    icon: prefImages.fashion,
    name: "Fashion"
  },
]
const recentEvents = [
  {
    id:1,
    banner:upImages.event1 ,
    programme:{
      name:"Praise Worship Friday",
      date:"June 11, 2021",
    },
    participants:120,
    status:"Occured"
  },
  {
    id:2,
    banner:registereImages.event4 ,
    programme:{
      name:"Gospel Concert",
      date:"september 18, 2021",
    },
    participants:120,
    status:"Pending"
  },
  {
    id:3,
    banner:upImages.event1 ,
    programme:{
      name:"Leadership Conference 2021",
      date:"June 11, 2021",
    },
    participants:200,
    status:"Occured"
  },
  {
    id:4,
    banner:registereImages.event1 ,
    programme:{
      name:"Bible Study Session",
      date:"August 20, 2021",
    },
    participants:120,
    status:"Pending"
  },
]
const reviewData =[
  {
    id:1,
    reviewer:review.reviewer1 ,
    comment:"Lorem Ipsum is simply dummy text of the printing and typesetting industry."
  },
  {
    id:2,
    reviewer:review.reviewer2,
    comment:"Lorem Ipsum is simply dummy text of the printing and typesetting industry."
  },
  {
    id:3,
    reviewer:review.reviewer3 ,
    comment:"Lorem Ipsum is simply dummy text of the printing and typesetting industry."
  },
]
export{
  upComingEvents,
  hosts,
  attended,
  registered,
  liveEvent,
  profilePendingEvents,
  myInterest,
  recentEvents,
  reviewData,
}