import React from "react";
import styled from "styled-components";
import Header from "../components/Layout/MainLayout";
import Footer from "../components/Footer";



const Pricing = () => {


  return(
    <Wrapper>
      <Header>
        
      </Header>
      <Footer />
    </Wrapper>
  )
}
export default Pricing;

const Wrapper = styled.main `

`;