import facebook from "./facebook.png";
import google from "./google.png";
import reghero from "./reg-hero.png";
import logHero from "./loginImg.png";
import calendar from "./calendar.svg";
import eventImg from './event.svg';
import facebooksvg from "./facebook.svg";
import twitter from "./twitter.svg";
import linkedin from "./linkedin.svg";
import instagram from "./instagram.png";
import verify from "./verifyImg.png";
import welcome from "./welcome.png";


const Images = {
  facebook,
  google,
  reghero,
  logHero,
  calendar,
  eventImg,
  verify,
  welcome,
  facebooksvg,
  instagram,
  twitter,
  linkedin,
  
}

export default Images;