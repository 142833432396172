import React from "react";
import { FaCalendar, FaMapMarkerAlt, FaRegHeart,  } from "react-icons/fa";
import {BsChat} from "react-icons/bs";
import styled from 'styled-components';



const EventsCard = (props) => {
  const {title, details} = props;
  return(
    <Wrapper>
      
      <h5>{title}</h5>
      <div className="outer-wrap">
        {details.map(eventdetails =>{
          return(
            <div className="card" key={eventdetails.id}>
              <img src={eventdetails.banner} className="banner" alt="event-banner"/>
              <div className="host-details">
                <img src={eventdetails.host.icon} className="logo" alt="host-logo"/>
                <div>
                  <p className="name">{eventdetails.host.name}</p>
                  <span>
                    <p><FaCalendar /> {eventdetails.host.date} </p>
                    <p><FaMapMarkerAlt />{eventdetails.host.venue}</p>
                  </span>
                </div>
              </div>
              <div className="actions-div">
                <button className="cta-btn">{eventdetails.action}</button>
                <span>
                  <BsChat className="icon"/>
                  <FaRegHeart className="icon"/>
                </span>
              </div>
            </div>
          )
        })}
      </div>
    </Wrapper>
  )
}

export default EventsCard;

const Wrapper = styled.div `
*{
  padding:0;
  margin:0;
  box-sizing:border-box;
}
  margin:3% !important;
  padding:10px 25px;
  border: 1px solid #FFFFFF33;
  box-shadow: 6px 6px 16px 4px #D1CDC780;
  border-radius:11px;

  .outer-wrap{
    display:grid;
    grid-template-columns:repeat(auto-fit, minmax(180px, 1fr));
    column-gap:10px;
    row-gap:15px;
    margin-top:7px;
    padding:5px;

    .card{
      box-shadow: 0px 3px 4px 0px #00000014;
      border: 0.5px solid #00000026;
      border-radius:5px;

      .banner{
      }
      .logo{
        width:70%;
      }
      .host-details{
        display:grid;
        grid-template-columns: 1fr 3fr;
        gap:0;
        margin:10px 5px 0 10px;
        p.name{
          font-size:11px;
          font-weight:800;
        }
        span{
          display:flex;
          gap:10px;
          font-size: 12px;
          margin-bottom:5px;
        }
      }

      .actions-div{
        display:flex;
        justify-content:space-between;
        margin:0 10px 15px 10px;
        
        .cta-btn{
          border:none;
          color:#fff;
          text-transform: capitalize;
          background:#03045E;
          padding:5px 25px;
          transition: all 1s ease-in;
        }
        .cta-btn:hover{
          border-radius:5px;
          font-weight:bold;
          background:linear-gradient(-90deg, red, #03045e);
          transform:translateX(10px);
        }
        span{
          padding:5px;

          .icon{
            cursor:pointer;
            margin:0 5px;
            transition: all 2s ease-out;
          }
          .icon:hover{
            transform:scale(1.5);
          }
        }
      }
    }
  }
  
`;