import host1 from "./host1.png";
import host2 from "./host2.png";
import host3 from "./host3.png";
import host4 from "./host4.png";

const images ={
  host1,
  host2,
  host3,
  host4,
}
export default images;