import event1 from "./event1.png";
import event2 from "./event2.png";
import event3 from "./event3.png";
const images ={
  event1,
  event2,
  event3,
}
export default images;


