import React from 'react';
import styled from "styled-components";
import { Bar } from 'react-chartjs-2';

const data = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
  datasets: [
    {
      label: 'Pending',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(75, 192, 192, 1)',
      ],
      borderColor: [
        'rgba(75, 192, 192, 1)',
      ],
      borderWidth: 1,
    },

    {
      label: 'Occured',
      data: [7, 9, 13, 15, 8, 13],
      backgroundColor: [
        'rgb(153, 3, 3)',
      ],
      borderColor: [
        'rgb(153, 3, 3)'
      ],
      borderWidth: 1,
    }
  ],
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const  EventChart = (props) =>{

  return(
    <Wrap>
       <Bar className="chart" data={data} options={options} />
    </Wrap>
  )
}
export default EventChart;

const Wrap = styled.div `
  padding:4px;
  width:98%;
  border-top:1px solid #ccc;
  border-bottom:1px solid #ccc;
  margin:14px 0 0 0 !important;
  .chart{
    height:210px !important;
  }
`;