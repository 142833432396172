import event1 from "./event1.png";
import event2 from "./event2.png";
import event3 from "./event3.png";
// import event4 from "./event4.png";

const liveImages ={
  event1,
  event2,
  event3,
//   host4,
}
export default liveImages;