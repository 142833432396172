import React from "react";
import styled from "styled-components";
import {GrCheckmark} from "react-icons/gr";




const Note = ({selected}) => {

  return(
    <Wrapper>
      <div className="dismiss-div">
        <GrCheckmark />
        <span onClick={() =>{selected("")}}>x</span>
      </div>
      <div className="notes">
        <textarea cols="10" rows="5" placeholder="Take Note..."></textarea>
        <div className="btn-div">
          <button className="save">Save</button>
        </div>
      </div>
    </Wrapper>
  )
}
export default Note;

const Wrapper = styled.div `
  
*{
  margin:0;
  padding:0;
  box-sizing:border-box;
}
  border:2px solid #eee;
  background:#ffffffab;
  padding: 5px;
  width: 180px;

  .dismiss-div{
    display:flex;
    justify-content:space-between;
    font-size:18px;
    align-items: center;
    margin-bottom: 5px;

    span{
      color: red !important;
      font-weight:600;
      cursor:pointer;
    }
  }
  .notes{
    margin:5px auto;

    textarea{
      width:100%;
    }
    textarea:focus{
      outline: none !important;
    }
    .btn-div{
      text-align: right;
      .save{
        color:#fff !important;
        background:#014109;
        border:none;
        padding: 5px 15px;
        cursor:pointer;
        border-radius:5px;
      }
    }
    
    
  }
`;