import React from "react";
import styled from "styled-components";



const EventReview = (props) => {

  const {reviewData, title} = props;
  return (
    <Wrap>
      <h5>{title}</h5>
      <div className="e-wrap">
        {
          reviewData.map(e =>{
            return(
              <div className="events" key= {e.id}>
                <div className="img">
                  <img src={e.reviewer} alt="banner" />
                </div>
                <div className="details-div">
                  <p className="event-name">{e.comment}</p>
                </div>
              </div>
            )
          })
        }
      </div>
    </Wrap>
  )
}
export default EventReview;

const  Wrap = styled.div `
  *{
    margin:0;
    padding:0;
    box-sizing:border-box;
  }
  padding:5px;

  h5{
    margin:5px 0 0 15px;
  }
  .events{
    display: flex;
    align-items:center;
    gap:20px;
    margin: 10px 10px;

    img{
      border-radius:50%;
    }
  }
`;