import React from "react";
import styled from "styled-components";
import homeImages from "../../../util/img/homeImg";
import {FaSearch} from "react-icons/fa";



const Header = (props) => {

  return(
    <HeaderWrapper className="main-contianer">
      <div>
		<div className="blue-stuff">
			<img src={homeImages.sticker} alt="sticker" />
		</div>

		<div className="navbar">
			<div className="container-center">
			<a href="/">
				<img src={homeImages.logo} className="logo"  width="150px" alt="logo" />
			</a>
			<input id="menu-btn" className="menu-btn" type="checkbox" />
			<label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
		
			<ul className="menu">
				<a href="/about">
					<li className="menu-item">About Us</li>
				</a>
				<a href="/how-it-works">
					<li className="menu-item">How it Works</li>
				</a>
				<a href="/solution">
					<li className="menu-item">Solutions</li>
				</a>
				<a href="/pricing">
					<li className="menu-item">Pricing</li>
				</a>
				<a href="/help">
					<li className="menu-item">Help</li>
				</a>
				<a href="/login">
					<li className="menu-item">Login</li>
				</a>
				
				<a href="/register">
					<li className="menu-item button-secondary">Sign up </li>
				</a>
				<li className="search-container">
					<input type="text" placeholder="" />
					<FaSearch className="search"/>
				</li>
			</ul>
			</div>
		</div>
      </div>
        {props.children}
    </HeaderWrapper>
  )
}
export default Header;

const HeaderWrapper = styled.div `


.blue-stuff {
	position: absolute;
	right: 0;
	top: 0;
}
.blue-stuff img{
	width:80%;
	height:auto;
	float:right !important;
}

/* navbar */
.navbar {
	width: 100%;
	height: 77px;
	background: rgba(0, 0, 0, 0.055);
	position: sticky !important;
	left: 0;
	top: 0;
	z-index: 999;
  }

  .navbar ul {
	margin: 0;
	padding: 0;
	padding-top: 1em;
	list-style: none;
	overflow: hidden;
	z-index: 999;
  }
  .search-container{
	position:relative;
  }
  .search-container .search{
	  position:absolute;
	  font-size:18px;
	  right:10px;
	  top:4px;
	  color:#c4c4c4;
  }
  .search-container input{
	  border: none;
	  background:transparent;
	  width:80px;
  }
  .search-container input:focus{
	width:120px;
	background:#fff;
  }
  @media (max-width: 1200px) {
	.navbar{
		height:72px;
	}
	.navbar ul {
		padding-top: 0em;
		padding-left: 1em;
		padding-right: 1em;
	}
	
	.blue-stuff img{
		width:55%;
	}
  }
  
  
  .navbar .logo {
	margin-top: -12px;
  }
  /* menu */
  
  .navbar .menu {
	clear: both;
	max-height: 0;
  }

  a {
	color: white !important;
	text-decoration:none;
  }
  
  .navbar .menu {
	width: 700px;
	display: grid;
	place-items: center;
	grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
	gap: 0;
  }

  @media (max-width: 1200px) {
	.navbar .menu {
		margin-top:-50px;
		background-color: #14005F;
		height: 90vh;
		width: auto;
		display: grid;
		place-items: center;
		grid-template-columns: 1fr;
		gap: 0px;
	  }
  }
  
  @media (max-width: 1064px) {
	
  }
  
  /* menu icon */
  
  .navbar .menu-icon {
	cursor: pointer;
	display: inline-block;
	float: right;
	margin-top: 40px;
	position: relative;
	user-select: none;
  }
  
  .navbar .menu-icon .navicon {
	background: white;
	display: block;
	height: 1px;
	position: relative;
	width: 16.5px;
	border:2px solid white;
  }
  
  .navbar .menu-icon .navicon:before,
  .navbar .menu-icon .navicon:after {
	background: black;
	content: "";
	display: block;
	height: 100%;
	position: absolute;
	width: 15px;
  }
  
  .navbar .menu-icon .navicon:before {
	top: 4px;
	border:2px solid #fff;
  }
  
  .navbar .menu-icon .navicon:after {
	top: -8px;
	border:2px solid #fff;
  }
  
  /* menu btn */
  
  .navbar .menu-btn {
	display: none;
  }
  
  .navbar .menu-btn:checked ~ .menu {
	max-height: 100vh;
  }
  
  .navbar .menu-btn:checked ~ .menu-icon .navicon {
	background:transparent;
	border:none;
  }
  
  .navbar .menu-btn:checked ~ .menu-icon .navicon:before {
	transform: rotate(-45deg);
  }
  
  .navbar .menu-btn:checked ~ .menu-icon .navicon:after {
	transform: rotate(45deg);
  }
  
  .navbar .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .navbar .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
	top: 0;
  }
  
  @media (min-width: 1064px) {
	.navbar {
	  position: sticky;
	}
  
	.menu-item {
	  padding: 0px 0px;
	}
  
	.navbar li {
	  float: left;
	  font-size: 14px;
	}
  
	.navbar li a {
	  padding: 20px 20px;
	}
  
	.navbar .menu {
	  clear: none;
	  float: right;
	  max-height: none;
	}
  
	.navbar .menu-icon {
	  display: none;
	}
  
	.navbar li a {
	  display: grid;
	  place-items: center;
	  padding: 15px 20px;
	  text-decoration: none;
	}
}
.container-center {
  width: 90% !important;
  height: 100%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .container-center {
      width: 100% !important;
  }
.logo {
  padding-left: 20px;
}
.navbar .menu-icon {
  padding-right: 20px;
}
}

.button-primary {
border-radius: 10px;
background-color: white;
color: black !important;
display: grid;
place-items: center;
padding: 15px;
width: 80px;
}

.button-secondary {
border-radius: 5px;
background-color: white;
color: black !important;
display: grid;
place-items: center;
padding: 5px 20px;
}
`