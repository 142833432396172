import event1 from "./event1.png";
import event2 from "./event2.png";
import event3 from "./event3.png";
import event4 from "./event4.png";
import event5 from "./event5.png";
import event6 from "./event6.png";
const images ={
  event1,
  event2,
  event3,
  event4,
  event5,
  event6,
}
export default images;


