import React,{useState,useEffect} from "react";
import styled from "styled-components";
import Images from "../../util/img/regImages";
import Loader from "react-loader-spinner";
import JoinOptions from "../../components/JoinOption";
import {useForm} from "react-hook-form";
import Axios from "axios";
// import url from "../../util/MiddleWare/client";

const Login = () => {
  
  const [loading,setLoading] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [opt, setOpt] = useState("");
  const {register, handleSubmit, formState:{errors},} = useForm();


  const onHandleSubmit= (data) =>{
    setErrorMsg('');
    let details={email:data.email, password:data.password};
    setLoading(true);
    setTimeout(() => {
      
      return Axios.post(`https://us-central1-elroi-ng-e2b68.cloudfunctions.net/app/api/v1/auth/login`,details)
        .then(async (res) =>{
          console.log(res.data);
          if(res.data){
            const {data} = await res.data;
            sessionStorage.setItem("token",data.token);
            sessionStorage.setItem("username",`${data.user.firstName} ${data.user.lastName}`)
          }
          setLoading(false);
          setOpt("join");
        })
        .catch(err =>{
          console.log(err.message);
          setErrorMsg("Invalid Email and Password.");
          setLoading(false);
        })
    }, 2000);
  }
  useEffect(() =>{
      
  },[]);
  return(
   
    <LoginDiv className="container-fluid">
      <div className={opt !==""?"hide":"row"}>
        <div className="text-center img-div col-md-7 col-sm-12">
          <img className="img-fluid" src={Images.logHero} alt="HeroImage" />
        </div>

        <div className="form-div col-md-5 col-sm-12">
          <h4>Let's get you started!</h4>
          <p>Don't have an Account yet? <a href="/register">Signup here.</a></p>

          <form onSubmit={handleSubmit(onHandleSubmit)}>
            {errorMsg &&  
              <div class="alert alert-danger alert-dismissible fade show" role="alert">
              <strong>{errorMsg}</strong> 
              </div>
            }
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input className="form-control" type="email" name="email" id="email" 
                {...register('email',{required:true})}
              />
              {errors.email && <p className="text-danger">Enter a valid Email</p>}
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input className="form-control" type="password" name="password" id="password" 
               {...register('password',{required:true})}
              />
              {errors.password && <p className="text-danger">Enter password.</p>}
            </div>
            <div className="form-group my-1">
              <label><input type="checkbox" /> Remember me.</label> 
            </div>
            <div className="form-group">
              { loading !==true ? <button type="submit" className="btn form-btn my-2">Login</button>:
                <Loader type="ThreeDots"  />
              }
            
            </div>
            <div className="form-group social-media">
              <p className="">Or Signup using</p>
              <img src={Images.facebook}  alt="facebook" />
              <img src={Images.google} className="mx-4" alt="google"/>
            </div>
          </form>
        </div>
      </div>
      <div className={opt ===""?"hide":""}>
        <JoinOptions />
      </div>
    </LoginDiv>
  )
}
export default Login;

const LoginDiv = styled.div `
  .hide{
    display:none;
  }
  .img-div{
    img{
      width:60%;
      margin:14% 5px 20px 0;
    }
  }
  .form-div{
    background:var(--bgColor);
    height:100vh;
    padding: 5% 7% 7px 2%;

    .close-alert:active{
      outline: none !important;
    }
    a{
      text-decoration:none;
    }
    form{
      width:90%;
    }
    
    .form-btn{
      background:var(--btnColor);
      width:100%;
      color:#fff;
      height:45px;
    }
    .social-media img{
      cursor:pointer;
    }

    @media screen and (max-width:780px){
      .img-div{
        img{
          width:90%;
          height:auto;
          margin:4% 5px 20px 0;
        }
      }
    }
  }
`;