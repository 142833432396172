import React from "react";
import styled from "styled-components";
import Emoji from "./Emoji";



const Review = ({selected}) => {

  return(
    <Wrapper>
      <div className="dismiss-div">
        <h6>Review this event</h6>
        <span onClick={() =>{selected("")}}>x</span>
      </div>
      <p>What do you think about this event?</p>
      <div className="reaction">
        <img src={Emoji.angry} alt="emoji" />
        <img src={Emoji.sad} alt="emoji" />
        <img src={Emoji.confused} alt="emoji" />
        <img src={Emoji.happy} alt="emoji" />
        <img src={Emoji.love} alt="emoji" />
      </div>
      <form onClick={(e) =>e.preventDefault()}>
        <div>
          <textarea cols="4" rows="6" placeholder="your thoughts..." ></textarea>
        </div>
        <button type="submit" >Send</button>
      </form>
    </Wrapper>
  )
}
export default Review;

const Wrapper = styled.div `
  *{
    margin:0;
    padding:0;
    box-sizing:border-box;
  }
  border:2px solid #eee;
  background:#ffffffab;
  padding:3px 15px 15px 15px;

  .dismiss-div{
    display:flex;
    justify-content:space-between;
    font-size:18px;
    align-items: center;
    margin: 5px auto 10px auto;

    span{
      color: red !important;
      font-weight:700;
      cursor:pointer;
      font-size:18px;
    }
  }
  .reaction{
    display:flex;
    gap:15px;
    align-items:center;
    margin: 10px auto;

    img{
      width:30px;
      cursor:pointer;
    }
  }
  form{
    text-align:left;
    div{
      width:100%;

      textarea{
        width:100%;
        color:#000 !important;
        border-radius:5px;
        padding:5px;
      }
      textarea:focus{
        outline:none;
      }
    }
    button{
      border:none;
      border-radius:10px;
      color:#fff;
      font-weight:bold;
      background: #0077B6;
      width:80px;
      height:34px;
    }
  }
`;