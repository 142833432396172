import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";


const  Error = () => {
 const History = useHistory();
  return (
    <ErrorWrap>
      <h4>404 Page</h4>
      <button onClick={() =>History.push("/")}>Back to Home</button>
    </ErrorWrap>
  )
}

export default Error;

const ErrorWrap = styled.div `
  text-align:center;
  h4{
    margin:6rem auto 0 auto;
  }
  button{
    background:var(--btnColor);
    color:#fff;
    border:none;
    font-weight:bold;
  }
`;