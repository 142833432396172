import React from "react";
import {Redirect } from "react-router-dom";
import Dashbord from "../../components/Layout/DashboardLayout";


const DashboardRoute = () =>{
  
  const token = sessionStorage.getItem("token");
  if(token){
    return <Dashbord />
  }else{
    return <Redirect to={{pathname:"/login"}} />
  }
}

export default DashboardRoute;