import React,{useState} from 'react';
import styled from "styled-components";
import Header from '../components/Layout/MainLayout';
import Footer from "../components/Footer";
import homeImages from '../util/img/homeImg';
import { useHistory } from 'react-router';
import JoinEventForm from '../components/JoinEventForm';


const Home = () => {

  const history = useHistory();
  const handleClick = (item) => {
    history.push(item);
  }
  const [join, setJoin] = useState(false);

  const CancelJoin = () => {
    setJoin(false);
  }

  return (
    <HomeWrap>
      <Header >
        <div className="hero">
          <div className="items ">
            <h1>Lorem Ipsum is simply dummy text.</h1>
            <p className="textcenter">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
              Lorem Ipsum has been the industry's standard.
              <span className="textcenter explore-link">Explore Event!!</span>
            </p>
            
            <div className="btn-divs">
              <button className="filled-btn" onClick={()=>setJoin(true)}>JOIN EVENT</button>
              <button className="outlined-btn" onClick={()=>handleClick("/login")}>CREATE EVENT</button>
            </div>
            {join === true && <JoinEventForm CancelJoin={CancelJoin} />}
          </div>
          <div className="demo">
            <img src={homeImages.explainer} alt="demo" />
          </div>
        </div>
        <div className="containerfluid">
          <h1 className="textcenter">Why EIRoi</h1>
          <p className="textcenter">Lorem Ipsum is simply dummy text of the printing.</p>
          <div className="row my-4">
            <div className="col-md-6 col-sm-12 textcenter">
              <div className="card y-ElRoi">
                <img src={homeImages.feed} className="img-fluid" alt="event" />
              </div>
              <h5>Event Feeds</h5>
              <p>Lorem Ipsum is simply dummy text of the printing.</p>
            </div>
            <div className="col-md-6 col-sm-12 textcenter">
              <div className="card y-ElRoi">
                <img src={homeImages.upload} className="img-fluid" alt="event" />
              </div>
              <h5>Upload or Download Files</h5>
              <p>Lorem Ipsum is simply dummy text of the printing.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 offset-md-2 col-sm-12 textcenter">
              <div className="card y-ElRoi">
                <img src={homeImages.analytic} className="img-fluid" alt="event" />
              </div>
              <h5>Event Analytics</h5>
              <p>Lorem Ipsum is simply dummy text of the printing.</p>
            </div>
            <div className="col-md-4 col-sm-12 textcenter">
              <div className="card y-ElRoi">
                <img src={homeImages.content} className="img-fluid" alt="event" />
              </div>
              <h5>Custom Made Contents</h5>
              <p>Lorem Ipsum is simply dummy text of the printing.</p>
            </div>
          </div>
        </div>
        <div className="how-it-works">
          <div className="net-container">
            <img src={homeImages.flow} className="" alt="network" />
          </div>
        </div>
        <div className="store textcenter mt-4">
          <div className="text-div">
            <h4>Lorem Ipsum is simply dummy text.</h4>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
              Lorem Ipsum has been the industry's standard.
            </p>
            <img src={homeImages.applestore} className="img-fluid apple" alt="app store" />
            <img src={homeImages.playstore} className="img-fluid" alt="app store" />
          </div>
          <div className="img-div">
            <img src={homeImages.phone} className="img-fluid" alt="app store" />
          </div>
        </div>
      </Header>
      <Footer />
    </HomeWrap>
  )
}

export default Home;

const HomeWrap = styled.div `
  .hero{
    display:flex;
    margin:11% 57px 183px 120px;

    .items{
      width:554px;
    
    }
  }
  .hero h1{
    font-size:30px;
    line-height:36px;
  }
  .hero p{
    font-size: 24px;
    line-height:28px;
    width:417px;
    margin:19px 0;
  }
  .hero .explore-link{
    font-size:22px;
    line-height:24px;
    font-weight:600;
    color:var(--btnColor);
  }
  
  .hero .filled-btn{
    background:var(--btnColor);
    color:#fff;
    margin-right:15px;
  }
  .hero .filled-btn:hover{
    background:#fff;
    border:1px solid var(--btnColor);
    color: var(--btnColor);
  }
  .hero .filled-btn, .hero .outlined-btn{
    border-style:none;
    width:170px;
    height:50px;
    border-radius:7px;
    font-weight: 600;
    font-size:20px;
    margin-top:15%;
    transform:all  10s easeout;
  }
  .hero .outlined-btn{
    border:2px solid var(--btnColor);
    background:#fff;
    color:var(--btnColor);
    cursor:pointer !important;
  }
  .outlined-btn:hover{
    background:var(--btnColor);
    border:none;
    color:#fff;
  }
  .hero .demo{
    position:absolute;
    right:10%;
  }
  .hero .demo img{
    width:78%;
  }
  h5{
    font-family:"Gilroy ExtraBold";
  }
  .how-it-works{
    background:var(--bgColor2);
    padding: 60px;

    @media screen and (max-width:450px){
      padding:20px;
    }
  }
  .how-it-works .net-container{
    width:60%;
    @media screen and (max-width:450px){
      width:97%;
      padding:5px;
    }
    margin:0 auto;
    padding:20px;
    img{
      width:100%;
      margin:0 auto;
    }
  }
  .textcenter{
    text-align:center;
  }
  .y-ElRoi{
    width:317px;
    height:276px;
    padding:25px 33px;
    box-shadow: 0 0 12px #6d6868 !important;
    border-radius: 15px;
    margin: 15px auto;
    
  }
  .store{
    display:flex;
    padding:81px 100px 88px 158px;
    gap:65px;
  }
  .store .text-div{
    width: 70%;
    h4{
      margin-bottom:40px;
      font-size:30px;
      line-height:36px;
      font-weight:800;
    }
    p{
      margin-bottom:102px;
      font-size:28px;
      font-height:24.3px;
      font-weight:400;
    }
    img{
      width: 40%;
    }
    img.apple{
      margin-right: 40px;
    }
  }
  @media screen and (max-width:980px){
    .hero{
      margin:10% 0 0 0;
      align-items:center;
      flex-wrap:wrap;
      padding: 2px auto;
    }
    .hero .items{
      width: 100%;
      padding:3% auto !important;
      text-align:center !important;
    }
    .hero h1{
      font-size:24px;
      line-height:26px;
    }
    .hero p{
      font-size: 18px;
      line-height:25px;
      width:90%;
      margin:28px 0 10px 0;
    }
    .hero .demo{
      position:static;;
      height: auto;
      text-align:center;
      border-radius:5px;
      margin: 2% auto;
    }
    .hero .filled-btn, .hero .outlined-btn{
      width:80%;
      margin:10px auto 5px auto;
    }

    .y-ElRoi{
      margin: 15px auto;
      width:80%;
    }
    .y-ElRoi img{
      width:100%;
      margin:auto 4px;
    }

    .how-it-works {
      padding:5px;
    }
    .how-it-works .net-container{
      padding: 30px 20px 60px 20px;
    }
    .how-it-works .client{
      width: max-content !important;
    }
    .how-it-works .img-abs{
      width:80px;
    } 
    .how-it-works .card-abs{
      height:87px;
      width: 65px;
      font-size:9px !important;
      line-height:10px !important;
    }
    .how-it-works .img-abs p {
      font-size:12px;
    }
    .how-it-works .img-abs img{
      max-width:30px;
    }
    .how-it-works .explore{
      top:77%;
      left:4%;
    }
    .how-it-works .download{
      top:56%;
    }
    .how-it-works .note{
      left:2%;
    }
    .how-it-works #join{
      left:11%;
    }
    .how-it-works .stream{
      top:42%;
      left:44%;
    }
    .how-it-works .chat{
      top:69%;
      left:40%;
    }
    .how-it-works .share{
      top:86%;
      left:41%;
    }
    .how-it-works .create{
      right:4.5%;
      top:11%;
    }
    .how-it-works .upload{
      top:80%;
      right:5%;
    }

    .store{
      flex-direction:column;
      padding:11px ;
      gap:5px;
      place-items:center;
    }
    .store .text-div{
      width: 100%;
      padding:10px;
      h4{
        margin-bottom:20px;
        font-size:24px;
        line-height:26px;
        font-weight:800;
      }
      p{
        margin-bottom:22px;
        font-size:18px;
        font-height:24.3px;
        font-weight:400;
      }
      img{
        width: 50%;
      }
      img.apple{
        margin-right: 0px;
      }
    }
    .store .img-div{
      padding:30px;
    }
  }
`;