import React,{useState} from "react";
import styled  from "styled-components";
import LayoutImages from "../../../util/img/StreamLayout";
import { FaCaretDown,FaCloudUploadAlt, FaCog, FaEdit, FaEye,FaMicrophone, FaShareAlt, FaShareSquare, FaUsers,} from "react-icons/fa";
import {MdFileUpload} from "react-icons/md";
import {RiMessage2Fill} from "react-icons/ri";
import {BsThreeDotsVertical} from "react-icons/bs";
import {CgVoicemailR} from "react-icons/cg";


const StreamLayout = (props) =>{

  const [active, setActive] = useState('');
  const [selectedOpt, setSelectedOpt] = useState('');

  const activeClass = (opt) => {
    if(active === opt){
      setActive("");
      return;
    }
    setActive(opt);
  }
  const selected = (item) => {
    if(selectedOpt === item){
      setSelectedOpt("")
      return;
    }
    
    setSelectedOpt(item);
    
  }
  const uploadFile = () =>{
    document.getElementById("file").click();
  }
  return(
    <Wrapper>
      <div className="stream-nav">
        <img src={LayoutImages.eroi} alt="logo" />
        <h6 className="logo-text">ElRoi</h6>
      </div>
      <div className="end-div">
        <span className="drop-down">
          <img src={LayoutImages.lock} alt="lock" />
          ElRoi <FaCaretDown />
        </span>
        <button className="btn-end">End</button>
      </div>
      
      <div className="live-show">
        <div className="metrix">
          <p> {props.title}</p>
          <span className="cta-btn">
            <button className="btn-live">Live</button>
            <button className="btn-view"><FaEye /> 3k</button>
          </span>
        </div>
        <div className={active ==='share'? "sharefile" : "sharefile hidden"}>
          <h3>Choose what to share</h3>
          <div className="items-to-share">
            <img src={LayoutImages.folder} alt='shareable item' />
            <img src={LayoutImages.powerpoint} alt='shareable item' />
            <img src={LayoutImages.powerpoint1} alt='shareable item' />
            <img src={LayoutImages.excel} alt='shareable item' />
            <img src={LayoutImages.word} alt='shareable item' />
            <img src={LayoutImages.pdf} alt='shareable item' />
          </div>
        </div>
        <div className={active==='share'? "live-streaming-wrap" : ""}>
          {props.children}
        </div>
      </div>
      {/* menu items */}
      <div className="menu-opt">
        <div onClick={()=>setActive("mute")}
          className={active ==='mute'? "opt text-center active-opt":
          "opt text-center"}
        >
          <FaMicrophone className="icon"/>
          <p>Mute</p>
        </div>


        {/* Upload file menu */}
        <div onClick={()=>{activeClass("upload"); selected("upload")}}
          className={active ==='upload'? "opt text-center active-opt":
          "opt text-center"}
        >
          <MdFileUpload className="icon"/>
          <p>Upload</p>

          <div className={selectedOpt ==="upload" && active==="upload"?"uploader":"uploader hidden"}> {/*menu to handle file upload */}
            <p>You can upload documents here for participants to download.</p>
            <span className="close-file" onClick={()=>{selected(" ");activeClass("")}}>x</span>
            <div>
              <input type="file" hidden id="file" />
              <span onClick={()=>uploadFile()}>
                <FaCloudUploadAlt className="upload-icon"/>
                <p className="browse-file">Browse your device</p>
              </span>
            </div>
          </div>
        </div>


        <div onClick={()=>activeClass("share")}
          className={active ==='share'? "opt text-center active-opt":
          "opt text-center"}
        >
          <FaShareSquare className="icon"/>
          <p>Share</p>
        </div>


        <div onClick={()=>activeClass("users")}
          className={active ==='users'? "opt text-center active-opt":
          "opt text-center"}
        >
          <FaUsers className="icon"/>
          <p>participants</p>
        </div>


        <div onClick={()=>activeClass("chat")}
          className={active ==='chat'? "opt text-center active-opt":
          "opt text-center"}
        >
          <RiMessage2Fill className="icon"/>
          <p>Chat Rooms</p>
        </div>


        <div onClick={()=>activeClass("recording")}
          className={active ==='recording'? "opt text-center active-opt":
          "opt text-center"}
        >
          <CgVoicemailR className="icon"/>
          <p>Record</p>

          <div className={active !=="recording"?"recording hidden":"recording"}>
            <p>Your  recording has started!</p>
            <p>Your record automatically saves in your device.</p>
          </div>
        </div>


        <div onClick={()=>{activeClass("more")}}
          className={active ==='more'? "opt text-center active-opt":
          "opt text-center"}
        >
          <BsThreeDotsVertical className="icon "/>
          <p>More</p>

          <div className={active !=="more" || selectedOpt !==""?"more-opt hidden":"more-opt"}>
            <p><FaShareAlt className="more-icon"/> Share event</p>
            <p onClick={()=>selected("event")}><FaCog className="more-icon"/> Event setting</p>
            <p><FaEdit className="more-icon"/>Take Note</p>
          </div>

          <div className={selectedOpt==="event"?"event-details":"event-details hidden"}>
            <span className="dismiss-item"><h6>The Believer’s Brook Weekdays Meeting</h6>
              <span className="close-icon" onClick={()=>{selected("");activeClass("")}}>X</span>
            </span>
            <div className="details">
              <div className="data-list">
                <span className="label">Event ID</span>
                <p>781 678 56667 345</p>
              </div>
              <div className="data-list">
                <span className="label">Host</span>
                <p>Mike Richards</p>
              </div>
              <div className="data-list">
                <span className="label">Password</span>
               <p>C6vv221</p>
              </div>
              <div className="data-list">
                <p className="label">Invite Link</p>
                <a href="/">https://ng06web.elroi.ng/p/78167856667345?tyz=MRtaQktPK3FZeGH1aFd</a>
              </div>
              <div className="data-list">
                <span className="label">Participant ID</span>
                <p>3601234</p>
              </div>
              <div className="data-list">
                <span className="label">Encryption</span>
                <p>Enabled</p>
              </div>
            </div>
            <p>You are connected to ElRoi Event via ........</p>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
export default StreamLayout;

const Wrapper = styled.div `
  max-height: 100%;
  .hidden{
    display:none;
  }
  .active-opt{
    color:var(--btnColor) !important;
  }


  .stream-nav{
    background:var(--primaryColor);
    padding:5px;
    position:relative;
    img{
      margin:5px 0 0 54px;
    }
    .logo-text{
      color:#fff;
      position:absolute;
      top:28px;
      left:80px;
    }
  }
  .end-div{
    background: #21214B;
    color:#fff;
    box-shadow: 0px 2px 2px 0px #00000040;
    overflow:hidden;

    .btn-end{
      background: #D42323;
      border:none;
      color:#fff;
      height:34px;
      width:143px;
      float:right;
      margin: 20px 24px 23px 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 14px;
      border-radius:11px;
    }
    .drop-down{
      img{
        margin:30px 11px 25px 52px;
      }
    }
    @media screen and (max-width: 600px){
      .btn-end{
        width:80px;
        margin: 20px 24px 23px 0;
        font-size: 18px;
        line-height: 20px;
      }
    }
  }


  .live-show{
    position: relative;
    width:100%;
    overflow:hidden;
    
    .sharefile{
      margin:11% auto 6% auto;
      width:98%;
      border:1px solid #ccc;
      border-radius:5px;
      padding: 10px;
      
      .items-to-share{
        display: grid;
        grid-template-columns:repeat(auto-fit, minmax(160px,1fr));
        gap: 40px;
        padding:10px;
        
        img{
          width:80%;
          cursor:pointer;
        }
      }
      @media screen and (max-width:450px){
        margin-top:18%;
        .items-to-share{
          grid-template-columns:repeat(auto-fit, minmax(90px,1fr));
          gap: 10px;
          img{
            width:80%;
            cursor:pointer;
          }
        }
      }
    }

    .live-streaming-wrap{
      background:red;
      width:350px;
      height:200px;
      margin:0 auto;
      position:absolute;
      right:5px;
      bottom:0px;

      @media screen and (max-width:450px){
        position:static;
      }
    }
  }


  .metrix{
    position:absolute;
    box-shadow: 2px 2px 2px 0px #0000000D;
    background: #FFFFFF6E;
    padding: 17px 25px 16px 52px;
    width: 100%;
    display: grid;
    grid-template-columns:3fr 1fr;
    align-items:center;

    .cta-btn{
      display:flex;
      justify-content:flex-end;
      .btn-live, .btn-view{
        width:69px;
        height:34px;
        color:#fff !important;
        border:none;
      }
      .btn-live{
        background:#D42323;
        margin-right:8px;
      }
      .btn-view{
        background:#000;
        color:#fff !important;
        width:69px;
        height:34px;
      }
    }
    @media screen and (max-width:600px){
      padding: 10px 5px 11px 5px;
      p{
        font-size:12px;
        width:max-content;
      }
      .cta-btn{
        .btn-live, .btn-view{
          width:43px;
          height:24px;
          font-size:12px;
        }
      }
    }
  }
  

  .menu-opt{
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding: 15px  10px;

    
    div.opt{
      cursor:pointer;
      position:relative;
      p{
        margin:0;
      }
      .icon{
        font-size: 20px;
      }
      @media screen and (max-width:768px){
        p{
          font-size:10px;
          font-weight:600;
        }
      }

      .more-opt{
        background:#ffffffab;
        color:#000 !important;
        border:2px solid #eee;
        padding:20px;
        text-align:left !important;
        cursor:default !important;
        width: 250px;
        position: absolute;
        top:-180px;
        right:0px;
        p{
          cursor:pointer;
          margin: 10px 0
        }
        .more-icon{
          margin-right:2px;
        }
      }
      
      // upload control start
      .uploader{
        width: 400px;
        border:2px solid #eee;
        background:#ffffffab;
        padding: 20px;
        p{
          font-size:18px;
          color:#000 !important;
        }
        cursor:default;
        position:absolute;
        left:-180px;
        top:-369px;

        .close-file{
          color:#D42323;
          font-size:1.8em;
          font-weight:bold;
          position:absolute;
          top:-5px;
          right:10px;
          cursor:pointer;
        }
        div{
          border:1px dashed #ccc;
          margin:20px auto;
          width:350px;
          height: 200px;

          p.browse-file{
            color:var(--btnColor) !important;
            cursor:pointer;
          }
          .upload-icon{
            margin:11% 0 0 0;
            font-size:65px;
            color:var(--btnColor);
            cursor:pointer;
          }
        }
        @media screen and (max-width:768px){
          left:-80px;
        }
        @media screen and (max-width:450px){
          left:-34px !important;
          width:300px !important;
          top:-310px !important;
          padding: 10px;
          p{
            font-size:12px;
          }

          .close-file{
            font-size:14px;
          }
          div{
            width:90%;
          }
        }
      } //uploader ends

      //Recording 
      .recording{
        width: max-content;
        border:2px solid #eee;
        background:#ffffffab;
        padding: 10px;
        p{
          font-size:12px;
          color:green !important;
        }
        position:absolute;
        top:-70px;
        right:-13%;
      }

      //event details begin
      .event-details{
        border:2px solid #eee;
        background:#ffffffab;
        padding:20px;
        cursor:default !important;
        width:480px;
        color:#000 !important;
        text-align:left !important;
        position:absolute;
        right:0;
        top:-320px;

        .dismiss-item{
          display:flex;
          justify-content:space-between;
          
          h6{
            font-size:16px;
          }
          .close-icon{
            color:#D42323;
            border-radius:50%;
            text-align:center;
            font-size:14px;
            padding:5px;
            font-weight:bold;
            cursor:pointer;
          }
        }
        .details{
          margin: 20px 0;
          word-wrap:break-word;

          .data-list{
            display:flex;
            gap:20px;
            font-size: 16px;
            p{
              text-align:left;
            }
            a{
              width:300px;
              font-size:14px;
              margin-left: 1.2rem;
            }
          }
          .label{
            font-weight:500;
            margin-right:10px;
            width:130px;
          }
        }
        @media screen and (max-width:450px){
          padding:10px;
          width:300px !important;
          right:0;
          top:-240px;
          .dismiss-item{
            
            h6{
              font-size:14px;
            }
            .close-icon{
              font-size:14px;
              padding:3px;
            }
          }
          .details{
            margin: 10px 0;
  
            .data-list{
              display:flex;
              gap:10px;
              font-size: 14px;
              p{
                text-align:left;
              }
              a{
                width:200px;
                font-size:11px;
                margin-left: 1rem;
              }
            }
            .label{
              font-size:14px !important;
              font-weight:bold;
              margin-right:0px;
            }
          }
        }
        @media screen and (max-width:768px){

        }
      }
    }
    
  }
`;