import React from "react";
import styled from "styled-components";



const AsideSection = (props) => {

  const {image, text} = props;
  return(
    <Wrap>
      <div>
        <img src={image} alt="" />
        <p>{text}</p>
      </div>
    </Wrap>
  )
}
export default AsideSection;

const Wrap = styled.div `
  *{
    padding:0;
    margin:0;
    box-sizing:border-box;
  }
  height:100%;
  background: #03045E;
  color:#fff;
  padding:5px;
  @media screen and (max-width:768px){
    height:auto;
    img{
      margin:5px auto 10% auto !important;
      width: 190px;
      height: 250.72px;
    }
  }
  div{
    padding:15px;
    text-align:center;
  }
  img{
    margin:45% auto 10% auto;
    width: 190px;
    height: 250.72px;
  }
`;