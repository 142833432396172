import React from 'react';
import styled from "styled-components";



const Toggler = (props) => {


	return(
		<Wrap>
			<CheckBox id={props.id} type="checkbox" />
			<CheckBoxLabel htmlFor={props.id} />
		</Wrap>
	)
}
export default Toggler;

const Wrap = styled.div `
	*{
		margin:0;
		padding:0;
		box-sizing:border-box;
	}
	position: relative;
	width:40px;
	
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 20px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 20px;
  &:checked + ${CheckBoxLabel} {
    background: #0077b6;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      margin-left: 25px;
      transition: 0.2s;
    }
  }
`;