import React,{useState} from "react";
import styled from 'styled-components';
import Images from "../util/img/regImages";
import JoinEventForm from "./JoinEventForm";


const JoinOptions = () => {

  const [join, setJoin] =useState(false);
  const CancelJoin = () =>{
    setJoin(false)
  }

  return(
    <Wrapper>
      <h1>What would you like to do?</h1>
      <div className="cards-wrap text-center">
        <div className="card ">
          <img src={Images.calendar} alt="calendar" />
          <a href="/user/dashboard/event" className="option">Create Event</a>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        </div>
        <div className="card">
          <img src={Images.eventImg} alt="event" />
          <p className="option" onClick={()=>setJoin(true)}>Join Event</p>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        </div>
      </div>
      <div>
        <h6>Explore our event feeds!!</h6>
        <a className="dashboard-link" href="/user/dashboard" style={{float:"right"}}>Go to Dashboard</a>
      </div>
      <div className={join !==true?"hide":"event-form"}>
        <JoinEventForm  CancelJoin={CancelJoin}/>
      </div>
    </Wrapper>
  )
}

export default JoinOptions;

const Wrapper = styled.div `
  padding: 23px 71px 29px 71px;
  margin: 20px auto;
  border-radius:11px;
  box-shadow: 0px 4px 4px 0px #00000040;
  width:877px;
  .cards-wrap{
    display: flex;
    gap:47px;
  }
  .card{
    width:344px;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius:11px;
    padding: 15px 47px 20px 47px;
  }
  .card img{
    width: 171px;
    height:158px;
  }
  .card .option{
    font-size:22px;
    font-weight:500;
    line-height:25.2px;
    color:var(--btnColor);
    cursor: pointer;
    text-decoration:none;
  }
  .card p{
    font-size:15px;
    font-weight:300;
    line-height:17.5px;
  }
  h6{
    font-size:30px;
    font-weight:300;
    margin:29px 0 0 0;
    color:var(--btnColor);
    text-align:center;
    
  }
  .dashboard-link{
    color:#00776b;
    font-weight:bold;
    font-size:14px;
  }
  .hide{
    display:none;
  }
  

  @media screen and (max-width:768px){
    padding: 13px;
    width:100%;
    margin: 5px auto;
    .cards-wrap{
      flex-direction: column;
      gap:27px;
      place-items:center;
    }

    .card{
      width:90%;
      padding: 15px 27px 20px 27px;
    }
    h1{
      font-size: 24px;
      margin:10px 0;
    }
    h6{
      font-size:28px;
      font-weight:400;
      margin:19px 0 0 0;
      color:var(--btnColor);
      text-align:center;
    }
  }
`;