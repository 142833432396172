import React from "react";
import styled from "styled-components";


const Report = ({selected}) => {

  return(
    <Wrapper>
      <div className="dismiss-div">
        <h6>Report this host</h6>
        <span onClick={() =>{selected("")}}>x</span>
      </div>
      <p>What do you wish to report about this host?</p>
      <p>Tick as many that applies: </p>
      <div className="issues">
        <div>
          <label><input type="checkbox" />Profane language</label>
        </div>
        <div>
          <label><input type="checkbox" />Selected scam</label>
        </div>
        <div>
          <label><input type="checkbox" />Cyber bullying</label>
        </div>
        <div>
          <label><input type="checkbox" />PDiscrimination of race.</label>
        </div>
        <div>
          <label><input type="checkbox" />Discrimination of gender.</label>
        </div>
        <div>
          <label><input type="checkbox" />Discrimination of marital status.</label>
        </div>
        <div>
          <label><input type="checkbox" />Discrimination of religion. </label>
        </div>
        <div>
          <label><input type="checkbox" />Information tend to compromise the safety or security of the public or public systems.</label>
        </div>
        <div>
          <label><input type="checkbox" />Conduct of encouragement of illegal activity.</label>
        </div>
        <div>
          <label><input type="checkbox" />Inappropraite sexual content or links to inapproapriate sexual content.</label>
        </div>
        <div>
          <label><input type="checkbox" />Contents not topically related to the purpose of the event.</label>
        </div>
        <div>
          <label><input type="checkbox" />Disclosure of private and confidential information.</label>
        </div>
        <button className="send">Report</button>
      </div>
    </Wrapper>
  )
}
export default Report;

const Wrapper = styled.div `
  *{
    margin:0;
    padding:0;
    box-sizing:border-box;
  }
  border:2px solid #eee;
  background:#ffffffab;
  padding:3px 15px 15px 15px;
  width:340px;
  height:500px;
  overflow-y: auto;
  text-align:left;
  color:#000;
  
  p{
    font-size:14px;
  }
  @media screen and (max-width:450px){
    width:300px;
  }

  .dismiss-div{
    display:flex;
    justify-content:space-between;
    font-size:18px;
    align-items: center;
    margin: 5px auto 15px auto;

    span{
      color: red !important;
      font-weight:700;
      cursor:pointer;
      font-size:18px;
    }
  }

  .issues{
    div{
      margin:5px 0;
    }
    input{
      margin-right: 8px;
    }
    input:checked{
      color: #03045e !important;
    }

    .send{
      border:none;
      padding:5px 15px;
      color:#fff;
      background:var(--btnColor);
      border-radius: 5px;
      margin: 15px 0 5px 0;
    }
  }
`;