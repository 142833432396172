
import Home from "./Pages/Home";
import Login from "./Pages/Auth/Login";
import SignUp from "./Pages/Auth/SignUp";
import Error from "./Pages/Error404/Error";
import {BrowserRouter as Router,Switch, Route, Redirect} from "react-router-dom";
import './App.css';
import About from "./Pages/About";
import LiveStream from "./Pages/LiveStream";
import ClientStream from "./Pages/ClientStream";
import PreferenceSetting from "./Pages/PreferenceSetting";
import Verification from "./Pages/Verification";
import Dashboard from "./util/MiddleWare/DashboardRoute";
import HowItWorks from "./Pages/HowItWorks";
import Solution from "./Pages/Solution";
import Pricing from "./Pages/Pricing";
import Help from "./Pages/Help";
import WelcomePage from "./Pages/Welcome";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route  path="/" exact >
            <Home />
          </Route>
          <Route  path="/login" exact>
            <Login />
          </Route>
          <Route  path="/register" exact>
            <SignUp />
          </Route>
          <Route  path="/about" exact>
            <About />
          </Route>
          <Route  path="/how-it-works" exact>
            <HowItWorks />
          </Route>
          <Route  path="/solution" exact>
            <Solution />
          </Route>
          <Route  path="/pricing" exact>
            <Pricing />
          </Route>
          <Route  path="/help" exact>
            <Help />
          </Route>
          {/* protected Routes here */}
          <Route  path="/live-streaming" exact>
            <LiveStream />
          </Route>
          <Route  path="/client-streaming" exact>
            <ClientStream />
          </Route>
          <Route path="/user/dashboard" >
            <Dashboard />
          </Route>
          <Route  path="/preferences" exact>
            <PreferenceSetting />
          </Route>
          <Route  path="/verify-account" exact>
            <Verification />
          </Route>
          <Route  path="/welcome" exact>
            <WelcomePage />
          </Route>

          <Route  path="/404">
            <Error />
          </Route>
          <Redirect to="/404">
            <Error />
          </Redirect>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
