import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import styled from "styled-components";
import {hosts,myInterest,profilePendingEvents} from "../../Data/data.js";
import HostFollowed from "../../HostFollowed";
import JoinEventForm from "../../JoinEventForm.js";

const Profile = (props) =>{

  const{profileImg, name, id, favourite, events, following} = props
  const uploadFile = () =>{
    document.getElementById("file").click();
  }
  const [join,setJoin] = useState(false);

  const CancelJoin = () =>{
    setJoin(false);
  }
  const handleBtnClick = (link) =>{
    window.location.href=link;
  }
  const logout = () =>{
    sessionStorage.clear();
    window.location.href ="/";
  }

  return(
    <Wrapper>
      <div className="main-div">
        <div className="status-div">
          <p onClick={()=>logout()}>Log out</p>
          <div>
            <button className="cta-join" onClick={()=>setJoin(true)}>Join Event</button>
            <button className="cta-create" onClick={() => handleBtnClick("/user/dashboard/event")}>Create Event</button>
          </div>
        </div>
       {join ===true && <div className="joinEvent">
          <JoinEventForm  CancelJoin={CancelJoin}/>
        </div>}
         <div className="profile-info">
          <div className="img-div">
            <img src={profileImg} alt=""/>
            <input type="file" hidden id="file"/>
            <FaEdit  className="icon" onClick={()=>uploadFile}/>
          </div>
          <div className="details">
            <h6>{name}</h6>
            <p>Personal Event ID:  {id}</p>
            <div className="stat">
              <p>{favourite}<br/>favourites</p>
              <p>{events}<br/>Events</p>
              <p>{following}<br/>Following</p>
            </div>
          </div>
        </div>
        <div className="interest">
          <div className="add-div">
            <h5>My Interests</h5> 
            <p className="add-link">Add</p>
          </div>
          <div className="card-wrap">
            {
              myInterest.map(interest =>{
                return(
                  <div className="card" key={interest.id}>
                    <img src={interest.icon} alt="icon" />
                    <p>{interest.name}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className="profile_info">
          <h5>My Profile</h5>
          <div className="user-data">
            <div>
              <label>First Name</label>
              <input type="text" defaultValue="Dorathy"/>
              <p>Edit</p>
            </div>
            <div>
              <label>Last Name</label>
              <input type="text" defaultValue="Yemi"/>
              <p>Edit</p>
            </div>
            <div>
              <label>Location</label>
              <input type="text" defaultValue="Lagos"/>
              <p>Edit</p>
            </div>
            <div>
              <label>Date Of Birth</label>
              <input type="date" />
              <p>Edit</p>
            </div>
            <div>
              <label>Password</label>
              <input type="password" defaultValue="password"/>
              <p>Edit</p>
            </div>
          </div>
          <p className="cta-close">Delete Account</p>
        </div>
      </div>
      <div className="aside-div">
        <div className="host-wrap">
          <div className="host-div">
            <HostFollowed title="Hosts you follow" hosts={hosts} />
          </div>
          <div className="host-div">
            <HostFollowed title="Favourites" hosts={hosts} />
          </div>
        </div>
        <div className="pending-event">
          <h5>Find your pending events here</h5>
          <div>
            {
              profilePendingEvents.map(pendingEvents =>{
                return(
                  <div className="events" key={pendingEvents.id}>
                    <p>{pendingEvents.time}</p>
                    <div>
                      <p className="topic">{pendingEvents.topic}</p>
                      <p className="id">Mettin ID:  {pendingEvents.meetingId}</p>
                    </div>
                    <button>{pendingEvents.action}</button>
                  </div>
                )
              })
            }
          </div>
        </div> 

      </div>
    </Wrapper>
  )
}
export default Profile;

const Wrapper = styled.div `
  *{
    padding:0;
    margin:0;
    box-sizing:border-box;
  }
  display:grid;
  grid-template-columns: 74% 26%;
  gap:20px;
  padding:16px 20px !important;
  background: #F5F5F5;

  @media screen and (max-width:768px){
    grid-template-columns:1fr;
    padding:20px;
    width:100%;
    margin:0 auto;
    gap:10px;
  }
  @media screen and (max-width:450px){
    padding:10px;
  }

  .main-div{

    @media screen and (max-width:768px){
      margin:0 auto;
      width: 97%;
    }
    @media screen and (max-width:450px){
      margin:0 auto;
      width: 100%;
    }
    .status-div{
      background:#fff;
      border-radius:9px;
      display:flex;
      justify-content:space-between;
      align-items:center;
      padding:15px;
      box-shadow: 4px 24px 60px 0px #00000026;
      
      @media screen and (max-width:450px){
        margin:0 auto;
        width:100% !important;

        div{
          display:flex;
          flex-direction:column;
          gap:5px;
          padding:0;
        }
      }

      p{
        font-size:18px;
        font-weight:500;
        cursor:pointer;
      }
      p:hover{
        color:red;
      }
      .cta-join, .cta-create{
        border:none;
        height:44px;
        width:163px;
        box-shadow: 1px 1px 2px 0px #03045E21;
        border-radius:10px;
        font-weight:800;
        font-size:20px;
        transition:1s;
      }
      .cta-join{
        background: #0077B6;
        color:#fff;
        margin-right:30px;

        @media screen and (max-width:450px){
          margin:0;
        }
      }
      .cta-join:hover{
        margin-right:15px;
        background-image: linear-gradient(to right, #1A2980 0%, #26D0CE  51%, #1A2980  100%);
      }
      .cta-create{
        border: 0.3px solid #0077B6;
        background:#fff;
        color:#0077b6;
      }
      .cta-create:hover{
        background-image: linear-gradient(to right, #1A2980 0%, #26D0CE  51%, #1A2980  100%);
        color:#fff;
      }
    }

    .profile-info{
      display:grid;
      grid-template-columns:1fr 2fr;
      margin:35px 0;

      @media screen and (max-width:768px){
        margin:20px auto;
      }
      @media screen and (max-width:450px){
        grid-template-columns:1fr;
        gap:1px;
      }

      .img-div{
        position:relative;
        img{
          width:70%;
          border-radius:50%;
        }
        .icon{
          color:#0077b6;
          font-size:24px;
          position:absolute;
          bottom:20px;
          right:95px;
          cursor:pointer;
        }
        @media screen and (max-width:450px){
          margin: 2px auto;
          width:90%;

          .icon{
            right:38%;
          }
        }
      }
      .details{
        box-shadow: 4px 24px 60px 0px #00000026;
        background:#fff;
        padding:20px;
        border-radius:8px;

        h6{
          margin:5px 0 15px 0;
        }
        .stat{
          display:flex;
          text-align:center;
          gap:30px;
          color:#0077b6;
          margin:40px 0 0 0;
        }
      }
    }

    .interest{
      box-shadow: 4px 24px 60px 0px #00000026;
      margin:45px 0 20px 0;
      padding:11px 21px 21px 16px;
      background:#fff;
      border-radius:11px;
      

      .add-div{
        display: flex;
        justify-content:space-between;

        .add-link{
          color:#0077b6;
          cursor:pointer;
          font-weight: 700;
        }
      }

      .card-wrap{
        display:flex;
        gap:15px;
        margin-top: 20px;

        @media screen and (max-width:450px){
          display: grid !important;
          grid-template-columns: repeat(auto-fit, minmax(99px,1fr));
          column-gap:5px;
          row-gap:5px;

        }

        .card{
          width:105px;
          height:100px;
          box-shadow: 0px 4px 4px 0px #00000040;
          padding:10px ;
          transition: 1.2s;
          @media screen and (max-width:450px){
            width:100%;
          }
          
          img{
            width:50px;
            height:50px;
            margin: 0 auto;
          }
          p{
            margin: 2px auto;
          }
        }
        .card:hover{
          transform:scale(1.2);
        }
      }
    }

    .profile_info{
      background:#fff;
      box-shadow: 4px 24px 60px 0px #00000026;
      margin:40px auto 10px auto;
      padding:5px 0;
      border-radius:5px;
      h5{
        margin:20px 0 0 20px;
        font-weight:700;
      }

      .user-data{
        margin-top:12px;
        border-top: 0.3px solid #00000026;
        div{
          display:flex;
          justify-content: space-between;
          padding:10px 20px;
          border-bottom: 0.3px solid #00000026;

          label{
            font-weight:500;
          }
          input{
            border:none;
            width:20%;
            text-align:left;
            @media screen and (max-width:450px){
              width:50%;
              text-align:center;
            }
          }
          input:focus{
            outline:none;
          }
          p{
            color:#0077b6;
            font-weight:400;
            font-size:18px;
            cursor:pointer;
          }
        }
      }
      .cta-close{
        color:red;
        font-size:22px;
        text-align:right;
        margin-left:auto;
        margin-right:20px;
        font-weight:600;
        width:max-content;

        cursor:pointer;
        transition:.8s;
      }
      .cta-close:hover{
        text-shadow: 2px 0 5px red;
        color:#fff;
        font-size:24px;
      }
    }
  }

  .aside-div{
    // background:#fff;
    @media screen and (max-width:768px){
      display:grid;
      grid-template-areas: "pending"
        "host"
    }
    .host-wrap{
      @media screen and (max-width:768px){
        grid-area: host;
        display:grid;
        grid-template-columns:1fr 1fr;
        gap:5px;
      }
    }
    .host-div{
      margin:0 15px 15px 0;
    }

    .pending-event{
      @media screen and (max-width:768px){
        grid-area: pending;
        
      }
      background:#fff;
      border: 1px solid #FFFFFF33;
      padding:15px 5px 27px 15px;
      box-shadow: 4px 24px 60px 0px #00000026;
      margin:0 15px 15px 0;

      h5{
        margin:3px 0 25px 0;
      }

      .events{
        display:flex;
        gap:10px;
        margin:5px auto 3px auto;

        p{
          font-size:14px;
        }
        .topic{
          font-weight:600;
          margin-bottom:0;
        }
        .id{
          margin:0;
          line-height:14px;
          font-size:11px;
        }
        button{
          background: #B0DAF1;
          width:69px;
          height:30px;
          border:none;
          border-radius:5px;
          transition: 0.5s;
        }
        button:hover{
          background-image: linear-gradient(to right, #1A2980 0%, #26D0CE  51%, #1A2980  100%);
          width:75px;
          color:#fff;
          font-weight:700;
        }
      }
    }
  }

`;